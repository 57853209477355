<script setup lang="ts">
import { defineProps, inject, onMounted, type Ref, ref } from 'vue'
import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection.ts'
import { storeToRefs } from 'pinia'
import { useGiPointStore } from '@/stores/giPoints'
import { useCalculationStore } from '@/stores/calculation'

const props = defineProps({
  closeDialog: { type: Function, required: false },
  linkedModelId: { type: Number, required: true },
  showCollectionName: { type: Boolean, required: false, default: true }
})

const files = ref([])
const loading = ref(false)
const giSetName = ref('')

const store = useGiDataUploadCollectionStore()
const giPointsStore = useGiPointStore()

const { currentItem } = storeToRefs(store)
const openedGroup = inject<Ref<number[]>>('openedGroup')

const uploadFile = async () => {
  loading.value = true
  const formData = new FormData()
  files.value.forEach((file) => {
    formData.append('source_files', file)
  })

  formData.append('project', props.linkedModelId)
  formData.append('name', giSetName.value)
  try {
    const isCreate = !currentItem.value?.id
    const response = await store.postOrUpdate(formData)
    if (response.gipoint_set) {
      if (isCreate) {
        const giPoints = response.gipoint_set.features
        giPointsStore.addItems(giPoints)
      }
    }
    if (response.id) {
      openedGroup?.value.push(response.id)
    }
    loading.value = false
    files.value = []
    props?.closeDialog()
  } catch (error) {
    console.log(error)
    loading.value = false
  }
}

onMounted(() => {
  giSetName.value = currentItem.value.name
})
</script>

<template>
  <div class="pa-3 pb-2 overflow-y-auto">
    <v-text-field
      v-if="showCollectionName"
      color="primary"
      label="Collection Name"
      v-model="giSetName"
      type="text"
      :clearable="true"
    ></v-text-field>

    <v-file-input
      color="primary"
      :multiple="true"
      v-model="files"
      prepend-icon=""
      append-icon="mdi-folder-zip"
      label="Upload GEF files"
      accept=".gef"
    >
      <template #selection="{ fileNames, totalBytes, totalBytesReadable }">
        <template v-for="fileName in fileNames">
          <div class="chip pt-1" style="width: 100%">
            {{ fileName }}
          </div>
        </template>
      </template>
    </v-file-input>

    <v-card-actions class="pa-0 justify-end">
      <v-btn color="primary" :disabled="loading" class="text-none" @click="props.closeDialog">
        Cancel
      </v-btn>
      <v-btn color="primary" :loading="loading" @click="uploadFile" class="text-none"> Save</v-btn>
    </v-card-actions>
  </div>
</template>
<style scoped>
.chip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
