import type { CPTType, TCPDataPointType } from '@/stores/type/cpt.type'
import { createTrace } from '@/utils/cpt/traceUtils'
import type { PlotData } from 'plotly.js-basic-dist'
import type { ModeType } from '@/types/chartType'

export const cptChartDataFromCPT = (
  cptData: CPTType | null,
  parameter: keyof TCPDataPointType,
  zCoordinate: number,
  color: string,
  mode: ModeType = 'marker'
): PlotData[] => {
  if (!cptData) {
    return []
  }
  let isNullLine = false
  const x = []
  for (const point of cptData.cptdatapoint_set) {
    if (point[parameter] === null) {
      isNullLine = true
      break
    }
    x.push(point[parameter] ?? 0)
  }
  if (isNullLine) {
    return []
  }
  const chartMode = mode === 'line' ? 'lines+text' : 'markers'
  const lineData = createTrace(
    parameter,
    x,
    cptData.cptdatapoint_set.map((point) => zCoordinate - point.depth),
    chartMode,
    'scatter',
    color
  )
  removeInvalidValues(lineData, 0)
  removeInvalidValues(lineData, lineData.x.length - 1)
  return [lineData]
}

function removeInvalidValues(lineData: PlotData, index: number) {
  const re = new RegExp('9{2,}.9+|9{3,}')
  const invalidValue = lineData.x[index]?.toString().match(re)
  if (invalidValue) {
    lineData.x.splice(index, 1)
    lineData.y.splice(index, 1)
  }
}
