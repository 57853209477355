<script setup lang="ts">
import { RHDHVForm as Form } from '@rhdhv/vue-component-library'
import type { StoreGeneric } from 'pinia'

const props = defineProps({
  name: { type: String, required: false },
  store: { type: Object, required: true }
})

const store = props.store as StoreGeneric

const { createUpdateDialog, writeLoading, currentItem } = storeToRefs(store)

const formValid = ref<boolean>(false)

const createOrUpdate = computed(() => {
  return currentItem.value['id']
    ? 'Update'
    : currentItem.value['duplicated_id']
    ? 'Duplicate'
    : 'Create'
})

async function createOrUpdateAction() {
  await props.store.postOrUpdate()
  props.store.toggleCreateUpdateDialog()
}
</script>
<template>
  <v-dialog
    :model-value="createUpdateDialog"
    @click:outside="store.toggleCreateUpdateDialog()"
    max-width="400"
  >
    <v-card class="pa-3">
      <v-card-title>{{ createOrUpdate }} {{ name }}</v-card-title>
      <v-card-text class="pl-4">
        <Form :store="store" state-name="currentItem" v-model="formValid">
          <template #prepend>
            <slot></slot>
          </template>
        </Form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="writeLoading"
          color="primary text-none"
          @click="store.toggleCreateUpdateDialog()"
        >
          Cancel
        </v-btn>
        <v-btn
          :loading="writeLoading"
          color="primary text-none"
          @click="createOrUpdateAction()"
          :disabled="!formValid"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
