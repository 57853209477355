<script lang="ts" setup>
import { inject, type PropType, type Ref } from 'vue'
import type { LiquefactionMethodType } from '@/stores/type/calculationCPTDataPoint.type'

const props = defineProps({
  data: {
    type: Array as PropType<
      { display_name: string; color: string; value: LiquefactionMethodType }[]
    >,
    required: true
  },
  size: {
    type: Number,
    default: 14
  }
})

let showLiqMethodLegend = inject<Ref<LiquefactionMethodType[]>>('showLiqMethodLegend')

function toggleLiqMethodLegend(method: LiquefactionMethodType) {
  if (showLiqMethodLegend?.value) {
    if (showLiqMethodLegend.value.includes(method)) {
      showLiqMethodLegend.value = showLiqMethodLegend.value.filter((item) => item !== method)
    } else {
      showLiqMethodLegend.value = [...showLiqMethodLegend.value, method]
    }
  }
}

const active = (method: LiquefactionMethodType) => {
  return showLiqMethodLegend?.value?.includes(method)
}
</script>

<template>
  <v-row class="px-6 py-4">
    <v-col v-for="(method, index) in data" v-bind:key="index" cols="6" class="px-1 py-0">
      <v-btn
        variant="text"
        block
        density="compact"
        class="header-text-container overflow-hidden text-none"
        @click="toggleLiqMethodLegend(method.value)"
      >
        <div
          :style="{
            width: `${size}px`,
            height: `${size}px`,
            backgroundColor: active(method.value) ? method.color : 'gray',
            borderRadius: '10%'
          }"
        ></div>
        <div :class="['pl-2', !active(method.value) ? 'legend-hidden' : '']">
          <span>{{ method.display_name }}</span>
        </div>
      </v-btn>
    </v-col>
  </v-row>
</template>

<style scoped>
.header-text-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.legend-hidden {
  text-decoration: line-through !important;
  color: #303030 !important;
}

.header-text-container :deep(.v-btn__content) {
  font-size: 11px;
}
</style>
