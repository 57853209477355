<script setup lang="ts">
import { useProjectParameterStore } from '@/stores/projectParameter'
import ProjectParameterForm from '@/components/project-parameter-drawer/ProjectParameterForm.vue'
import RHDHVDrawer from '@/components/rhdhv-drawer/RHDHVDrawer.vue'

const projectParameterStore = useProjectParameterStore()
const authorizationStore = useAuthorizationStore()

const { currentItem } = storeToRefs(projectParameterStore)

const canChangeProjectParameter = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['change_projectparameter']
  })
})
</script>

<template>
  <RHDHVDrawer state-name="projectParameterDrawer" :temporary="true">
    <div v-if="currentItem?.id">
      <v-card-title class="pt-4">
        <div>Project parameters</div>
      </v-card-title>
      <v-card-subtitle class="pl-3" style="white-space: break-spaces">
        Project parameters are the parameters that are used for the this project.
      </v-card-subtitle>
      <v-divider class="mt-2 mb-2"></v-divider>
      <ProjectParameterForm
        v-if="currentItem"
        :store="projectParameterStore"
        :item="currentItem"
        :readonly="!canChangeProjectParameter"
      ></ProjectParameterForm>
    </div>
  </RHDHVDrawer>
</template>
