<script setup>
import { _ } from 'lodash'

import ProjectSideBar from '@/components/project-detail/ProjectSideBar.vue'
import Map from '@/components/project-detail/Map.vue'
import DrawerField from '@/components/project-detail/DrawerField.vue'

import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection'
import { useGiPointStore } from '@/stores/giPoints'
import { useCalculationStore } from '@/stores/calculation'
import { onMounted } from 'vue'
import { useAuthorizationStore } from '@/stores/authorization'
import { useProjectUserStore } from '@/stores/projectUser'
import { useProjectParameterStore } from '@/stores/projectParameter'
import { storeToRefs } from 'pinia'

const route = useRoute()

const store = useProjectStore()
const giDataUploadCollectionStore = useGiDataUploadCollectionStore()
const giPointStore = useGiPointStore()
const calculationStore = useCalculationStore()
const calculationParameterStore = useCalculationParameterStore()
const projectParameterStore = useProjectParameterStore()
const projectUserStore = useProjectUserStore()
const authorizationStore = useAuthorizationStore()
const authStore = useDjangoAuthStore()

const { currentItem: currentProject } = storeToRefs(useProjectStore())
const { items: projectUsers } = storeToRefs(projectUserStore)
const { user: currentUser } = storeToRefs(authStore)

onMounted(async () => {
  if (!currentProject.value || _.isEmpty(currentProject.value)) {
    await projectUserStore.fetchItems({ search: route.params.id })
    await authorizationStore.fetchItems()

    const currentProjectUser = projectUsers.value.find(
      (item) => item.user === currentUser?.value?.id
    )
    authorizationStore.setCurrentRole([currentProjectUser?.role])

    await calculationStore.fetchOptions()
    await projectParameterStore.fetchOptions()
    await calculationParameterStore.fetchOptions()
    await giPointStore.fetchOptions()
    await store.fetchItem(route.params.id)
    await giDataUploadCollectionStore.fetchItems({ search: currentProject.value.id })
    await projectParameterStore.fetchItem(currentProject.value.projectparameter)
    await calculationParameterStore.fetchItem(currentProject.value.calculationparameters)
    await calculationStore.fetchItems({ search: currentProject.value.id })
    await giPointStore.fetchItems({ search: currentProject.value.id })
  }
})
</script>
<template>
  <DrawerField>
    <ProjectSideBar />
    <Map />
  </DrawerField>
</template>
