export function getPrettyFromOptions(value, fields, prop) {
  let result = []
  const field = fields[prop].child ? fields[prop].child : fields[prop]
  field.choices.forEach((choice) => {
    if (value.includes(choice.value)) {
      result.push(choice.display_name)
    }
  })
  return result.join(', ')
}
