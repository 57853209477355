<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useProjectStore } from '@/stores/project'
import { useRoute } from 'vue-router/dist/vue-router'
import { computed } from 'vue'
import { RHDHVToolbar } from '@rhdhv/vue-component-library'

const props = defineProps({
  title: { type: String, required: true }
})

const store = useProjectStore()

const { currentItem: currentProject } = storeToRefs(useProjectStore())

const route = useRoute()

const currentRouteName = computed(() => route.name)
</script>

<template>
  <RHDHVToolbar>
    <template v-slot:toolbar-prepend>
      <v-app-bar-nav-icon
        class="ml-1"
        small
        color="white"
        @click="store.toggleDrawer"
        v-if="currentRouteName == 'project-detail'"
      ></v-app-bar-nav-icon>
    </template>
    <template v-slot:toolbar-title>
      <div class="d-flex justify-center align-center">
        <v-toolbar-title class="app-title ml-2">{{ props.title }}</v-toolbar-title>
      </div>
    </template>
    <template v-slot:breadcrumbs-items>
      <v-breadcrumbs-item>
        <router-link
          class="text-white toolbar-text text-decoration-none"
          :to="{ name: 'project-index' }"
        >
          Projects
        </router-link>
      </v-breadcrumbs-item>

      <template v-if="currentProject?.id">
        <v-breadcrumbs-divider />
        <v-breadcrumbs-item>
          <router-link
            class="text-white toolbar-text text-decoration-none"
            :to="{ name: 'project-index' }"
          >
            {{ currentProject?.name }}
          </router-link>
        </v-breadcrumbs-item>
      </template>
    </template>
  </RHDHVToolbar>
</template>
