<script setup lang="ts">
import { computed, type PropType } from 'vue'
import GIPointBaseRow from '@/components/base/GIPointBaseRow.vue'
import type { CalculationCPTType } from '@/stores/type/calculationCPT.type'

const props = defineProps({
  item: { type: Object as PropType<CalculationCPTType>, required: true },
  showSelect: { type: Boolean, default: false }
})

const calculationCPTStore = useCalculationCPTStore()
const giPointStore = useGiPointStore()

const { selectedItems } = storeToRefs(calculationCPTStore)

const giPoint = computed(() => {
  return giPointStore.getGIPointByCPTId(props.item.cpt)
})

const isSelected = computed({
  get: () => {
    return selectedItems.value?.findIndex((item: any) => item.id === props.item.id) !== -1
  },
  set: () => {
    calculationCPTStore.toggleSelectedItem(props.item?.id)
  }
})
</script>
<template>
  <GIPointBaseRow
    v-if="giPoint"
    :item="giPoint"
    show-select
    :class="{
      'selected-item': isSelected
    }"
  >
    <template #select>
      <v-checkbox
        @click.stop=""
        color="primary"
        density="compact"
        v-model="isSelected"
        hide-details
        class="ml-n1"
      ></v-checkbox>
    </template>
  </GIPointBaseRow>
</template>
