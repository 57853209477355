<script lang="ts" setup>
import {
  RHDHVHeaderLogo,
  DocumentationButton,
  RHDHVHeaderMenu,
  RHDHVLoader
} from '@rhdhv/vue-component-library'
import { storeToRefs } from 'pinia'
import ToolBar from '@/components/app/ToolBar.vue'
import { useCalculationStore } from '@/stores/calculation'
import { useCalculationCPTStore } from '@/stores/calculationCPT'
import { useProjectParameterStore } from '@/stores/projectParameter'
import { useCalculationParameterStore } from '@/stores/calculationParameter'
import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection'
import { useProjectUserStore } from '@/stores/projectUser'

const authStore = inject('useAuthStore') as any
const router = useRouter()

const { writeLoading: projectParWriteLoading } = storeToRefs(useProjectParameterStore())
const { writeLoading: calculationWriteLoading } = storeToRefs(useCalculationStore())
const { writeLoading: cptWriteLoading } = storeToRefs(useCalculationCPTStore())
const { writeLoading: calculationParLoading } = storeToRefs(useCalculationParameterStore())
const { writeLoading: collectionLoading } = storeToRefs(useGiDataUploadCollectionStore())
const { writeLoading: projectUserLoading } = storeToRefs(useProjectUserStore())

const { user } = storeToRefs(authStore)

const loading = computed(() => {
  return (
    projectParWriteLoading.value ||
    calculationWriteLoading.value ||
    cptWriteLoading.value ||
    calculationParLoading.value ||
    collectionLoading.value ||
    projectUserLoading.value
  )
})

async function logout() {
  await authStore.logout()
  await authStore.$patch({
    user: null
  })
  await router.push({ name: 'auth-login' })
}

const goToProjectIndex = () => {
  router.push({ name: 'project-index' })
}
</script>

<template>
  <v-app-bar v-if="user" fixed app height="70" extension-height="40">
    <RHDHVHeaderLogo @logo-click="goToProjectIndex" height="90" width="150" />
    <v-spacer></v-spacer>
    <RHDHVLoader :loading="loading" />
    <DocumentationButton />
    <RHDHVHeaderMenu v-if="user" :user="user" @logout="logout()" color="#a9a9a9"></RHDHVHeaderMenu>
    <template v-slot:extension>
      <ToolBar title="Liquefaction" />
    </template>
  </v-app-bar>
</template>
