<script lang="ts" setup>
import { useGiPointStore } from '@/stores/giPoints'
import { computed, type PropType } from 'vue'
import type { CalculationCPTType } from '@/stores/type/calculationCPT.type'
import type { CalculationType } from '@/stores/type/calculation.type'
import { useCalculationCPTStore } from '@/stores/calculationCPT'
import { useCPTStore } from '@/stores/cpt'
import { storeToRefs } from 'pinia'
import CalculationCPTTableRow from '@/components/calculation-drawer/CalculationCPTTableRow.vue'
import GIPointBaseTable from '@/components/base/GIPointBaseTable.vue'

const projectStore = useProjectStore()
const calculationCPTStore = useCalculationCPTStore()
const cptStore = useCPTStore()
const giPointStore = useGiPointStore()

const { resultDrawer } = storeToRefs(projectStore)
const { currentStrippedItem } = storeToRefs(calculationCPTStore)
const { currentItem: currentCPT } = storeToRefs(cptStore)

const props = defineProps({
  item: {
    type: Object as PropType<CalculationType>,
    required: true
  },
  searchQuery: {
    type: String,
    required: false,
    default: ''
  },
  isSelectedTable: {
    type: Boolean,
    required: false
  },
  showSelect: { type: Boolean, default: false }
})

const filteredItems = computed<CalculationCPTType[]>(() => {
  const items = props.item?.calculationcpt_set ?? []
  if (props.searchQuery) {
    return items.filter((item) => {
      return item.location_id.toLowerCase().includes(props.searchQuery.toLowerCase())
    })
  }
  return items
})

function toggleResult(): void {
  if (!resultDrawer.value.shown) {
    projectStore.toggleResultDrawer()
  } else if (currentStrippedItem.value?.id === props.item.id) {
    projectStore.toggleResultDrawer()
    return
  }
}

async function selectCalculationCPT(calculationCPT: CalculationCPTType): Promise<void> {
  calculationCPTStore.setCurrentStrippedItem(calculationCPT)
  toggleResult()
  await cptStore.fetchOrGetCPT(calculationCPT.cpt)
  if (currentCPT.value?.gi_point) {
    const giPoint = giPointStore.getItemById(currentCPT.value?.gi_point)
    if (giPoint) {
      giPointStore.setCurrentItem(giPoint)
    }
  }
  await calculationCPTStore.fetchOrGetCalculationCPT(calculationCPT)
}
</script>

<template>
  <GIPointBaseTable
    :items="filteredItems"
    :show-select="props.showSelect"
    :is-selected-table="isSelectedTable"
  >
    <template v-slot:item="{ item }: { item: any }">
      <CalculationCPTTableRow
        :item="item"
        :show-select="isSelectedTable"
        @click="selectCalculationCPT(item)"
      />
    </template>
  </GIPointBaseTable>
</template>
