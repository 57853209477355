<script lang="ts" setup>
import { computed, onMounted } from 'vue'

const { calculationDrawer, resultDrawer, giDrawer } = storeToRefs(useProjectStore())

const giDrawerWidth = ref(0)
const resultDrawerWidth = ref(0)
const calculationDrawerWidth = ref(0)

function updateCalculationDrawerWidth() {
  calculationDrawerWidth.value = calculationDrawer.value.shown ? calculationDrawer.value.width : 0
}

function updateResultDrawerWidth() {
  resultDrawerWidth.value = resultDrawer.value.shown ? resultDrawer.value.width - 4 : 0
}

function updateGiDrawerWidth() {
  giDrawerWidth.value = giDrawer.value.shown ? giDrawer.value.width : 0
}

watch(
  () => calculationDrawer.value.shown,
  () => {
    updateCalculationDrawerWidth()
  }
)

watch(
  () => calculationDrawer.value.width,
  () => {
    updateCalculationDrawerWidth()
  }
)

watch(
  () => resultDrawer.value.shown,
  () => {
    updateResultDrawerWidth()
  }
)

watch(
  () => resultDrawer.value.width,
  () => {
    updateResultDrawerWidth()
  }
)

watch(
  () => giDrawer.value.shown,
  () => {
    updateGiDrawerWidth()
  }
)

watch(
  () => giDrawer.value.width,
  () => {
    updateGiDrawerWidth()
  }
)

const projectSideBarWidth = computed(() => {
  return giDrawerWidth.value + resultDrawerWidth.value + calculationDrawerWidth.value
})

onMounted(() => {
  giDrawerWidth.value = giDrawer.value.shown ? giDrawer.value.width : 0
})
</script>

<template>
  <div
    :style="{
      'padding-left': projectSideBarWidth + 'px'
    }"
  >
    <slot></slot>
  </div>
</template>
