<script setup lang="ts">
import DeleteDialog from '@/components/dialog/DeleteDialog.vue'
import RHDHVDrawer from '@/components/rhdhv-drawer/RHDHVDrawer.vue'
import GIDataCollectionIndex from '@/components/gi-drawer/GIDataCollectionIndex.vue'

import type { GiDataUploadCollectionType } from '@/stores/type/giPoint.type'

import { useCalculationStore } from '@/stores/calculation'
import { useCalculationCPTStore } from '@/stores/calculationCPT'
import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection'
import { useGiPointStore } from '@/stores/giPoints'

const giDataUploadCollectionStore = useGiDataUploadCollectionStore()
const giPointStore = useGiPointStore()
const calculationStore = useCalculationStore()
const calculationCPTStore = useCalculationCPTStore()

const {
  deleteItem,
  selectedItems: selectedGICollections,
  items: giDataUploadCollectionItems
} = storeToRefs(giDataUploadCollectionStore)
const { currentItem: currentGIPoint, selectedItems: selectedGiPoints } = storeToRefs(giPointStore)
const { items: calculationItems } = storeToRefs(calculationStore)

const onRemoveCPTs = (cptIds: number[]) => {
  calculationItems.value.forEach((calculation) => {
    calculation.calculationcpt_set = calculation.calculationcpt_set.filter(
      (calculation) => !cptIds.includes(calculation.cpt)
    )
  })
  cptIds.forEach((cptId) => {
    calculationCPTStore.removeItemById(cptId)
  })
}

async function onGiDataUploadCollectionDelete(deletedItems: GiDataUploadCollectionType[]) {
  calculationStore.clearSelectedItems()
  calculationCPTStore.clearSelectedItems()
  deletedItems.forEach((item) => {
    calculationStore.removeItemsByCollectionId(item.id)
    item.gipoint_set.features.forEach((item) => {
      giPointStore.removeItem(item)
    })
    const cptIds = item.gipoint_set.features.map((item) => item.properties.cpt.id)
    onRemoveCPTs(cptIds)
  })
  giDataUploadCollectionStore.clearSelectedItems()
}

function onGIPointDelete() {
  calculationStore.clearSelectedItems()
  calculationCPTStore.clearSelectedItems()
  if (selectedGiPoints.value.length === 0) {
    return
  }
  if (currentGIPoint.value && typeof currentGIPoint.value?.id != 'number') {
    giPointStore.setCurrentItem({})
  }
  const giPointIds = selectedGiPoints.value.map((item) => item.id)
  giDataUploadCollectionItems.value.forEach((item) => {
    item.gipoint_set.features = item.gipoint_set.features.filter((giPoint) => {
      return !giPointIds.includes(giPoint.id)
    })
  })
  const cptIds = selectedGiPoints.value.map((item) => item.properties.cpt.id)
  onRemoveCPTs(cptIds)
  giPointStore.clearSelectedItems()
  giDataUploadCollectionStore.clearSelectedItems()
}
</script>

<template>
  <RHDHVDrawer state-name="giDrawer" :temporary="true">
    <v-list class="px-0 py-2">
      <v-list-item class="rounded-0">
        <h3 class="font-weight-medium">Ground Investigation Data Management</h3>
        <v-list-item-subtitle>
          Upload files & manage Ground Investigation Data.
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-lazy>
      <GIDataCollectionIndex />
    </v-lazy>
    <DeleteDialog
      bulk-delete
      :items-to-delete="selectedGICollections"
      :store="giDataUploadCollectionStore"
      :name="deleteItem?.name"
      @deleted="onGiDataUploadCollectionDelete"
    >
      <template v-slot:info>
        <v-alert
          v-if="giDataUploadCollectionStore.hasCalculationCPTRelatedToSelectedItems()"
          dense
          type="warning"
          variant="outlined"
        >
          <span
            >GI Points and CPTs associated with the selected GI Data Collections were used in
            calculations. These will be removed as well.</span
          >
        </v-alert>
      </template>
    </DeleteDialog>
    <DeleteDialog
      :store="giPointStore"
      :name="giPointStore.deleteItem?.properties?.location_id"
      bulk-delete
      :items-to-delete="selectedGiPoints"
      @deleted="onGIPointDelete"
    >
      <template v-slot:info>
        <v-alert
          v-if="giPointStore.hasCalculationCPTRelatedToSelectedItems()"
          dense
          type="warning"
          variant="outlined"
        >
          <span
            >The selected GI Points and CPTs were used in calculations. These will be removed as
            well.</span
          >
        </v-alert>
      </template>
    </DeleteDialog>
  </RHDHVDrawer>
</template>

<style scoped>
.resize-handler-gi {
  border-right-width: 10px;
  z-index: 1;
  box-shadow: none;
}

.hidden {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease;
}

:deep(.v-navigation-drawer__content) {
  overflow-y: hidden;
}

.gi-drawer {
  transition: border-bottom-color 0.3s;
  border-right: 10px solid rgba(0, 0, 0, 0.1);
}
</style>
