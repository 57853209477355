<script lang="ts" setup>
import { type PropType } from 'vue'
import { isFilterMatch } from '@/utils/filter'

import GIPointRow from '@/components/gi-drawer/GIPointRow.vue'
import GIPointBaseTable from '@/components/base/GIPointBaseTable.vue'

import { useGiPointStore } from '@/stores/giPoints'
import type { GiDataUploadCollectionType } from '@/stores/type/giPoint.type'

const giPointsStore = useGiPointStore()

const props = defineProps({
  item: {
    type: Object as PropType<GiDataUploadCollectionType>,
    required: true
  },
  selectedFilter: {
    type: String,
    required: false
  },
  searchQuery: {
    type: String,
    required: false
  },
  isSelectedTable: {
    type: Boolean,
    required: false
  },
  showSelect: { type: Boolean, default: false }
})

const filteredGIPointsByType = (giPointCollection: GiDataUploadCollectionType) => {
  const selectedType = props.selectedFilter
  const searchTerm = props.searchQuery?.toLowerCase()

  return giPointCollection.gipoint_set.features.filter((feature) => {
    const locationId = feature.properties?.location_id?.toLowerCase()
    const giType = feature.properties?.gi_type
    const searchMatch = searchTerm && searchTerm !== '' ? locationId.includes(searchTerm) : true
    const filterMatch = selectedType ? isFilterMatch(giType, selectedType) : true
    return searchMatch && filterMatch
  })
}
</script>

<template>
  <GIPointBaseTable
    :items="filteredGIPointsByType(item)"
    :show-select="props.showSelect"
    :is-selected-table="isSelectedTable"
  >
    <template v-slot:item="{ item }: { item: any }">
      <GIPointRow
        :item="item"
        :store="giPointsStore"
        :show-select="showSelect"
        @click="giPointsStore.setCurrentItem(item)"
      />
    </template>
  </GIPointBaseTable>
</template>
