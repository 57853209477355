<script setup lang="ts">
import { onMounted } from 'vue'

import ProjectNavigationDrawer from './ProjectNavigationDrawer.vue'
import GIDrawer from '../gi-drawer/GIDrawer.vue'
import ResultDrawer from '@/components/result-drawer/ResultDrawer.vue'
import UserManagementDrawer from '@/components/user-management/UserManagementDrawer.vue'
import ProjectParameterDrawer from '@/components/project-parameter-drawer/ProjectParameterDrawer.vue'
import CalculationDrawer from '@/components/calculation-drawer/CalculationDrawer.vue'

import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection'
import { useGiPointStore } from '@/stores/giPoints'
import { useCalculationStore } from '@/stores/calculation'
import { useCalculationCPTStore } from '@/stores/calculationCPT'

const store = useProjectStore()
const giDataUploadCollectionStore = useGiDataUploadCollectionStore()
const giPointStore = useGiPointStore()
const calculationStore = useCalculationStore()
const calculationCPTStore = useCalculationCPTStore()

const {
  resultDrawer: resultDrawerRef,
  userManagementDrawer: userManagementDrawerRef,
  calculationDrawer: calculationDrawerRef,
  giDrawer: giDrawerRef,
  projectParameterDrawer: projectParameterDrawerRef
} = storeToRefs(store)

onMounted(() => {
  calculationStore.setSelectedItems([])
  calculationCPTStore.setSelectedItems([])
  giDataUploadCollectionStore.setSelectedItems([])
  giPointStore.setSelectedItems([])
})
</script>

<template>
  <div class="h-100">
    <ProjectNavigationDrawer />
    <GIDrawer v-if="giDrawerRef.shown" />
    <CalculationDrawer v-if="calculationDrawerRef.shown" />
    <UserManagementDrawer v-if="userManagementDrawerRef.shown" />
    <ProjectParameterDrawer v-if="projectParameterDrawerRef.shown" />
    <ResultDrawer v-if="calculationDrawerRef.shown && resultDrawerRef.shown" />
  </div>
</template>

<style scoped></style>
