<script setup lang="ts">
import { useProjectStore } from '@/stores/project'
import { computed } from 'vue'

import { useCPTStore } from '@/stores/cpt'

import CalculationResult from '@/components/result-drawer/CalculationResult.vue'

import RHDHVDrawer from '@/components/rhdhv-drawer/RHDHVDrawer.vue'

const store = useProjectStore()
const cptStore = useCPTStore()
const calculationStore = useCalculationStore()
const calculationCPTStore = useCalculationCPTStore()

const {
  currentItem: currentCalculationCPT,
  fetchLoading: calculationCPTLoading,
  currentStrippedItem: currentStrippedCalculationCPT
} = storeToRefs(calculationCPTStore)
const { currentItem: currentCPT, fetchLoading: cptLoading } = storeToRefs(cptStore)

const resultLoading = computed<boolean>(() => {
  return (
    !currentCPT.value?.id ||
    cptLoading.value ||
    !currentCalculationCPT.value?.id ||
    calculationCPTLoading.value
  )
})

const left = computed<number>(() => {
  const projectNavigationDrawerWidth = store.drawer.shown ? store.drawer.width : 56
  return projectNavigationDrawerWidth + store.calculationDrawer.width
})
</script>

<template>
  <RHDHVDrawer state-name="resultDrawer" :temporary="true" :style="`left: ${left}px`">
    <v-card class="fill-height">
      <v-list-item>
        <div class="mr-3 d-inline middle-div" style="font-size: 18px">
          <span class="pr-2 border-right-grey" style="border-width: 2px"
            >{{
              calculationStore.getCalculationById(currentStrippedCalculationCPT?.calculation)?.name
            }}
          </span>
          <span class="pl-2">{{ currentStrippedCalculationCPT?.location_id }}</span>
        </div>
        <span class="float-right">
          <v-btn
            icon="mdi-close"
            size="small"
            variant="text"
            @click.stop="store.toggleResultDrawer(false)"
          ></v-btn>
        </span>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-item class="px-0 py-0 pb-3 fill-height">
        <CalculationResult v-if="!resultLoading" />
        <v-card class="fill-height">
          <v-overlay v-model="resultLoading" :contained="true" class="align-center justify-center">
            <v-progress-circular indeterminate color="primary" size="48"></v-progress-circular>
          </v-overlay>
        </v-card>
      </v-card-item>
    </v-card>
  </RHDHVDrawer>
</template>

<style scoped>
:deep(.v-overlay__scrim) {
  background-color: white;
}

:deep(.v-card-item__content) {
  height: 100%;
}
</style>
