import AppBaseStore from './baseStore'
import { defineStore } from 'pinia'
import type { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import API from '@/api/apiUrls'
import type { CalculationParameterType } from '@/stores/type/calculationParameter.type'
import { useApi } from '@/api/useApi'

const { api } = useApi()

type State = BaseStoreType<CalculationParameterType>

const baseUrl = API.CORE.CALCULATION_PARAMETERS
const baseStore: BaseStoreType<CalculationParameterType> = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<CalculationParameterType> = baseStore.actions

export const useCalculationParameterStore = defineStore({
  id: 'calculationParameter',
  state: (): State => ({
    ...baseStore.state,
    loading: false,
    filterFields: ['name']
  }),
  actions: {
    ...baseStoreActions,
    resetAll(): void {
      this.$reset()
    }
  },
  getters: {
    ...baseStore.getters
  }
})
