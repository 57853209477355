import AppBaseStore from './baseStore'
import { defineStore } from 'pinia'
import { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import API from '@/api/apiUrls'
import type { ProjectPermissionType, Role } from '@/stores/type/authorization.type'
import { useApi } from '@/api/useApi'

type State = BaseStoreType<Role> & { currentRoles: Role[] }

const { api } = useApi()

const baseUrl = API.ROLES.INDEX
const baseStore = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<Role> = baseStore.actions

export const useAuthorizationStore = defineStore({
  id: 'authorization',
  state: (): State => ({
    ...baseStore.state,
    currentRoles: []
  }),
  actions: {
    ...baseStoreActions,
    setCurrentRole(roleIds: number[]) {
      roleIds.forEach((roleId: number) => {
        const role = this.getCurrentRole(roleId)
        const isExist = this.currentRoles.find((item) => item.id === roleId)
        if (role && !isExist) {
          this.currentRoles.push(role)
        }
      })
    },
    getCurrentRole(roleId: number) {
      return this.items.find((item) => item.id === roleId)
    },
    hasServicePermission({ authSource, allowed }: { authSource: string; allowed: string[] }) {
      const authRoles = this.currentRoles.find((role) => role.auth_source === authSource)
      return authRoles
        ? authRoles.permissions.some((permission) => allowed.includes(permission))
        : false
    },
    resetState() {
      this.items = []
      this.currentRoles = []
      this.setCurrentRole([])
    },
    async getServicePermissionByRole({
      roleItems,
      permission
    }: {
      roleItems: { id: number; role: number }[]
      permission: string[]
    }): Promise<ProjectPermissionType[]> {
      this.resetState()
      await this.fetchItems()
      const authPermission = []
      roleItems.forEach((roleItem) => {
        const authPermissionItem = []
        permission.forEach((permissionItem) => {
          const authRoles = this.getCurrentRole(roleItem.role)
          if (!authRoles) return
          const hasPermission = authRoles.permissions.includes(permissionItem)
          if (hasPermission) {
            const p = permissionItem.split('_')[0]
            authPermissionItem.push(p)
          }
        })
        authPermission.push({
          id: roleItem.id,
          role: roleItem.role,
          permissions: authPermissionItem
        })
      })
      return authPermission
    }
  },
  getters: {
    ...baseStore.getters
  }
})
