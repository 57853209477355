import type { PlotData } from 'plotly.js-basic-dist'
import { createTrace } from '@/utils/cpt/traceUtils'
import type { CalculationCPTType } from '@/stores/type/calculationCPT.type'
import type {
  CalculationCPTDataPointType,
  LiquefactionMethodType
} from '@/stores/type/calculationCPTDataPoint.type'
import { METHOD_LINE_COLOR } from '@/utils/lineColor'
import type { ChoiceType } from '@/stores/type/store.type'
import type { ModeType } from '@/types/chartType'

export const cptChartDataFromCalculationCPT = (
  calculationCPTData: CalculationCPTType | null,
  parameter: keyof CalculationCPTDataPointType,
  choices: Array<ChoiceType<LiquefactionMethodType>>,
  showLine: LiquefactionMethodType[],
  mode: ModeType = 'marker'
): PlotData[] => {
  if (!calculationCPTData) {
    return []
  }
  const data = []
  choices.forEach((choice) => {
    const methodField = choice.value.toLowerCase() as keyof CalculationCPTType
    if (calculationCPTData[methodField].length) {
      data.push(
        getLineData(
          calculationCPTData,
          parameter,
          choice.display_name,
          choice.value,
          mode,
          !showLine.includes(choice.value)
        )
      )
    }
  })
  return data
}

function getLineData(
  calculationCPTData: CalculationCPTType,
  parameter: keyof CalculationCPTDataPointType,
  name: string,
  method: LiquefactionMethodType,
  mode: 'line' | 'marker' = 'line',
  hide?: false
): PlotData {
  const x = []
  const y = []
  const methodField = method.toLowerCase() as keyof CalculationCPTType
  for (const point of calculationCPTData[methodField]) {
    if (point[parameter] === null || point[parameter] >= 999) {
      continue
    }
    x.push(point[parameter] ?? 0)
    y.push(point.level)
  }
  const chartMode = mode === 'line' ? 'lines+text' : 'markers'
  return createTrace(name, x, y, chartMode, 'scatter', METHOD_LINE_COLOR[method], hide)
}
