<script setup lang="ts">
import { type PropType } from 'vue'
import { computed } from 'vue'
import type { GiDataUploadCollectionType } from '@/stores/type/giPoint.type'
import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection'
import GICollection from '@/components/gi-drawer/GICollection.vue'
import GIPointTable from '@/components/gi-drawer/GIPointTable.vue'

const props = defineProps({
  items: { type: Array as PropType<GiDataUploadCollectionType[]>, required: true },
  showTreeOnly: { type: Boolean, required: false, default: false },
  searchQuery: { type: String, required: false, default: '' },
  selectedFilter: { type: String, required: false, default: '' }
})

const giDataUploadCollectionsStore = useGiDataUploadCollectionStore()
const authorizationStore = useAuthorizationStore()

const { selectedItems: selectedGiDataCollections } = storeToRefs(giDataUploadCollectionsStore)

const canChangeGIPointCollection = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['change_gidatauploadcollection']
  })
})
</script>

<template>
  <template v-if="items.length">
    <v-list-group
      v-for="giPointCollection in items"
      :key="giPointCollection.id"
      :value="giPointCollection.id"
      subgroup
    >
      <template v-slot:activator="{ props: menuProps }">
        <v-list-item
          :nav="true"
          :v-ripple="true"
          class="w-100 py-0 my-0 list-item rounded-0"
          :class="{ 'selected-item': selectedGiDataCollections?.includes(giPointCollection) }"
          v-bind="menuProps"
        >
          <GICollection :store="giDataUploadCollectionsStore" :item="giPointCollection" />

          <template #append>
            <div class="float-right" v-if="canChangeGIPointCollection && !showTreeOnly">
              <v-icon
                color="primary"
                class="cursor-pointer"
                @click.stop="giDataUploadCollectionsStore.toggleUploadDialog(giPointCollection)"
                >mdi-pencil
              </v-icon>
            </div>
          </template>
        </v-list-item>
      </template>
      <v-divider />
      <GIPointTable
        :item="giPointCollection"
        :search-query="searchQuery"
        :selected-filter="selectedFilter"
        :is-selected-table="selectedGiDataCollections.includes(giPointCollection)"
        show-select
      ></GIPointTable>
      <v-divider thickness="3" />
    </v-list-group>
  </template>
  <template v-else>
    <div class="text-center" style="width: 100%">
      <small style="opacity: 0.6"> There is no data available.</small>
    </div>
  </template>
</template>

<style scoped>
:deep(.v-list-item__prepend) {
  width: 20px;
  margin-left: 4px;
  margin-right: 4px;
}

.selected-item,
.list-item:hover {
  background-color: #f4fcff;
}
</style>
