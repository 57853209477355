<script setup lang="ts">
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer } from '@vue-leaflet/vue-leaflet'
import { computed, onMounted, ref, watch } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import MarkerWrapper from './MarkerWrapper.vue'
import { useGiPointStore } from '@/stores/giPoints'

const giPointsStore = useGiPointStore()
const { items: giPoints } = storeToRefs(giPointsStore)

const map = ref()
const container = ref<HTMLElement | null>(null)
const RECENTER_MAP_DELAY_MS = 500

const mapContainerStyle = computed(() => {
  return {
    height: '88vh',
    width: `100%`
  }
})

const debounceInvalidateSize = () => {
  setTimeout(() => {
    map.value?.leafletObject?.invalidateSize(true)
  }, RECENTER_MAP_DELAY_MS)
}

onMounted(() => {
  // recenter the map when the container size changes
  useResizeObserver(container, debounceInvalidateSize)
})

const flyToMarker = (markerCoordinates: number[]) => {
  map.value.leafletObject.flyTo(markerCoordinates, 13)
}

watch(giPoints, (value) => {
  if (value.length) {
    const coordinates = [...value[0].geometry.coordinates] as number[]
    flyToMarker([coordinates[1], coordinates[0]])
  }
})
</script>

<template>
  <div ref="container" :style="mapContainerStyle">
    <l-map
      :use-global-leaflet="false"
      ref="map"
      :zoom="4"
      :center="[47.41322, -1.219482]"
      :min-zoom="8"
      :max-zoom="18"
      :options="{ doubleClickZoom: false }"
    >
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer>
      <MarkerWrapper
        @fly-to-marker="flyToMarker"
        v-for="marker in giPoints"
        :item="marker"
        :key="marker.id"
        :map="map"
      />
    </l-map>
  </div>
</template>

<style scoped></style>
