<script setup lang="ts">
import { provide, type Ref, ref, watch } from 'vue'

import CalculationIndex from '@/components/calculation-drawer/CalculationIndex.vue'
import CalculationViewDialog from '@/components/calculation/CalculationViewDialog.vue'
import RHDHVDrawer from '@/components/rhdhv-drawer/RHDHVDrawer.vue'
import DeleteDialog from '@/components/dialog/DeleteDialog.vue'
import CalculationCreateDialog from '@/components/calculation/CalculationCreateDialog.vue'
import CreateOrUpdateDialog from '@/components/dialog/CreateOrUpdateDialog.vue'

import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection'
import { useCalculationStore } from '@/stores/calculation'
import type { CalculationType } from '@/stores/type/calculation.type'

const giDataUploadCollectionStore = useGiDataUploadCollectionStore()
const giPointStore = useGiPointStore()

const projectStore = useProjectStore()
const calculationStore = useCalculationStore()
const calculationCPTStore = useCalculationCPTStore()

const {
  items: calculationItems,
  selectedItems: selectedCalculations,
  createUpdateDialog
} = storeToRefs(calculationStore)
const { currentItem: currentCalculationCPT, selectedItems: selectedCalculationCPTs } =
  storeToRefs(calculationCPTStore)

const onDeleteCurrentCalculationCPT = () => {
  projectStore.toggleResultDrawer(false)
  calculationCPTStore.setCurrentItem({})
}

function onCalculationDelete(deletedItems: CalculationType[]) {
  deletedItems.forEach((item) => {
    if (currentCalculationCPT.value?.calculation === item.id) {
      onDeleteCurrentCalculationCPT()
    }
    calculationCPTStore.removeItemsByCalculationId(item.id)
  })
  calculationStore.clearSelectedItems()
  calculationCPTStore.clearSelectedItems()
}

function onCalculationCPTDelete() {
  if (typeof currentCalculationCPT.value?.id !== 'number') {
    onDeleteCurrentCalculationCPT()
  }
  selectedCalculationCPTs.value.forEach((item) => {
    calculationItems.value.forEach((calculation) => {
      calculation.calculationcpt_set = calculation.calculationcpt_set.filter(
        (cpt) => cpt.id !== item.id
      )
    })
  })
  calculationStore.clearSelectedItems()
  calculationCPTStore.clearSelectedItems()
}

const calculationsViewShowDialog = ref<boolean>(false)
provide<Ref<boolean>>('calculationsViewShowDialog', calculationsViewShowDialog)

watch(calculationsViewShowDialog, (value) => {
  if (!value) {
    giDataUploadCollectionStore.setCurrentItem({})
  }
})

watch(createUpdateDialog, () => {
  giPointStore.clearSelectedItems()
  giDataUploadCollectionStore.clearSelectedItems()
})

const updateCalculation = async () => {
  const response = await calculationStore.postOrUpdate()
  if (response) {
    calculationStore.toggleCreateUpdateDialog()
  }
}
</script>

<template>
  <RHDHVDrawer state-name="calculationDrawer" :temporary="true">
    <v-list class="px-0 py-2">
      <v-list-item class="rounded-0">
        <h3 class="font-weight-medium">Calculation management</h3>
        <v-list-item-subtitle>
          Manage calculations and visualize the liquefaction results
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-lazy>
      <CalculationIndex></CalculationIndex>
    </v-lazy>
    <CalculationViewDialog></CalculationViewDialog>

    <DeleteDialog
      :store="calculationStore"
      bulk-delete
      :items-to-delete="selectedCalculations"
      :name="calculationStore.deleteItem?.name"
      @deleted="onCalculationDelete"
    />
    <DeleteDialog
      :store="calculationCPTStore"
      bulk-delete
      :items-to-delete="selectedCalculationCPTs"
      :name="calculationCPTStore.deleteItem?.location_id"
      @deleted="onCalculationCPTDelete"
    />
    <CalculationCreateDialog></CalculationCreateDialog>
    <CreateOrUpdateDialog
      :store="calculationStore"
      name="calculation"
      @save="updateCalculation()"
    />
  </RHDHVDrawer>
</template>
