import { useApi } from '@/api/useApi'
import API from '@/api/apiUrls'

import type { djangoAuthStateType } from '@/stores/type/authDjango.type'

const { api } = useApi()

const currentUser = API.CORE.USERS.CURRENT_USER

export const useDjangoAuthStore = defineStore('auth', {
  state: (): djangoAuthStateType => ({
    user: null,
    isInitDone: true
  }),
  actions: {
    async authenticate({ email, password }) {
      await api.apiService.authenticationHandler.authenticate(email, password)
      const userResponse = await api.service(currentUser).get()
      return { user: userResponse }
    },
    async reAuthenticate() {
      await api.apiService.authenticationHandler.setAccessTokenInHeaderFromStore()
      try {
        const userResponse = await api.service(currentUser).get()
        return { user: userResponse }
      } catch (e) {
        return { user: null }
      }
    },
    async logout() {
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
      this.user = null
    },
    hasServicePermission(serviceName, permission) {
      return true
    },
    hasObjectPermission(object, permission) {
      return true
    }
  }
})
