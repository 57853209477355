<script setup lang="ts">
import GefFileUpload from '@/components/gi-drawer/GefFileUpload.vue'
import { storeToRefs } from 'pinia'

const store = useGiDataUploadCollectionStore()

const { currentItem, showUploadDialog } = storeToRefs(store)

const props = defineProps({
  linkedModelId: { type: Number, required: true },
  title: { type: String, required: false, default: '' },
  subTitle: {
    type: String,
    required: false,
    default: 'Ground investigation data upload collection'
  },
  showCollectionName: { type: Boolean, required: false, default: true }
})

const createOrUpdateTitle = computed(() => {
  let title = ''
  if (currentItem.value?.id) {
    title += 'Edit'
  } else {
    title += 'Upload'
  }
  return title
})

const closeDialog = () => {
  store.toggleUploadDialog()
  store.setCurrentItem({})
}
</script>

<template>
  <v-dialog :model-value="showUploadDialog" max-width="500" @click:outside="closeDialog">
    <v-card class="pa-3">
      <v-card-title class="pb-0"> {{ createOrUpdateTitle }}</v-card-title>
      <v-card-subtitle> {{ props.subTitle }}</v-card-subtitle>
      <GefFileUpload
        class="mt-2"
        :show-collection-name="props.showCollectionName"
        :close-dialog="closeDialog"
        :linked-model-id="linkedModelId"
      />
    </v-card>
  </v-dialog>
</template>
