<script setup lang="ts">
import { useCalculationStore } from '@/stores/calculation'
import { storeToRefs } from 'pinia'
import { computed, inject, type Ref } from 'vue'
import { filteredHeaders } from '@/components/calculation/calculationHeaders'
import CalculationTableRow from '@/components/calculation/CalculationTableRow.vue'

const store = useCalculationStore()

const { currentItem, selectedItems } = storeToRefs(store)

const dialog = inject<Ref<boolean>>('calculationsViewShowDialog')

const filteredHeadersComponent = computed(() => {
  return currentItem.value ? filteredHeaders(currentItem.value) : []
})

function close() {
  if (dialog) {
    dialog.value = false
  }
}
</script>

<template>
  <v-dialog :model-value="dialog" @click:outside="close()">
    <v-card class="pa-3">
      <v-card-title> Calculation log</v-card-title>
      <v-card-text class="pl-4">
        <v-data-table
          key="id"
          item-value="id"
          :items="selectedItems"
          :headers="filteredHeadersComponent"
          color="primary"
          :items-per-page="10"
          :fixed-header="true"
          height="500px"
        >
          <template v-slot:item="{ item }">
            <CalculationTableRow :item="item" />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.table-width {
  width: 200px;
}

.cell-width {
  width: 75px;
}

.th {
  width: 200px !important;
}

::v-deep .v-selection-control {
  color: #0186a7; /* Change this to your desired blue color */
}
</style>
