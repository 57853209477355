<script lang="ts" setup>
import { computed } from 'vue'

const authorizationStore = useAuthorizationStore()
const giDataUploadCollectionStore = useGiDataUploadCollectionStore()
const giPointStore = useGiPointStore()

const { selectedItems: selectedGIDataCollections } = storeToRefs(giDataUploadCollectionStore)
const { selectedItems: selectedGiPoints } = storeToRefs(giPointStore)

const canDeleteGiDataUploadCollection = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['delete_gidatauploadcollection']
  })
})

const canDeleteCPT = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['delete_cpt']
  })
})
const disableDeleteButton = computed(() => {
  return (
    (!selectedGIDataCollections.value?.length && !selectedGiPoints.value?.length) ||
    !canDeleteGiDataUploadCollection.value ||
    !canDeleteCPT.value
  )
})
</script>

<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
        color="primary"
        class="text-none my-1"
        variant="tonal"
        prepend-icon="mdi-delete-outline"
        append-icon="mdi-menu-down"
        v-bind="props"
        :disabled="disableDeleteButton"
        >Delete
      </v-btn>
    </template>
    <v-list class="py-0">
      <v-list-item
        v-if="selectedGIDataCollections?.length > 0 && canDeleteGiDataUploadCollection"
        density="compact"
        v-ripple
        @click="giDataUploadCollectionStore.toggleDeleteDialog()"
      >
        <v-list-item-title class="cursor-pointer"
          >Delete Selected GI Data Collections
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedGiPoints?.length > 0 && canDeleteCPT"
        density="compact"
        v-ripple
        @click="giPointStore.toggleDeleteDialog()"
      >
        <v-list-item-title class="cursor-pointer">Delete Selected GI Point</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
