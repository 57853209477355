<script setup lang="ts">
import { computed, ref } from 'vue'

import CreateOrUpdateDialog from '@/components/dialog/CreateOrUpdateDialog.vue'
import CalculationList from '@/components/calculation-drawer/CalculationList.vue'

import { useCalculationStore } from '@/stores/calculation'
import CalculationControl from '@/components/calculation-drawer/CalculationControl.vue'

const searchQuery = ref('')

const calculationStore = useCalculationStore()

const {
  items: calculationItems,
  selectedItems: selectedCalculations,
  fetchLoading
} = storeToRefs(calculationStore)

const selectAll = computed({
  get() {
    return selectedCalculations.value?.length === calculationItems.value.length
  },
  set: (value) => {
    if (value) {
      calculationStore.selectAllItems()
    } else {
      calculationStore.clearSelectedItems()
    }
  }
})

const filteredCalculations = computed(() => {
  const search = searchQuery.value
  if (!search) {
    return calculationItems.value
  }
  return calculationItems.value.filter((item) => {
    const calculationFilter = item.name.toLowerCase().includes(search.toLowerCase())
    const calculationCPTFilter = item.calculationcpt_set.some((cpt) =>
      cpt.location_id.toLowerCase().includes(search.toLowerCase())
    )
    return calculationFilter || calculationCPTFilter
  })
})
</script>

<template>
  <v-container class="pa-0">
    <CalculationControl v-model="searchQuery" />
    <v-card-subtitle v-if="filteredCalculations.length">
      To visualize the liquefaction result, click on the CPT row in the calculation table.
    </v-card-subtitle>
    <v-list density="compact">
      <template v-if="calculationItems.length">
        <v-list-item v-if="!searchQuery" class="py-0" :class="{ 'selected-item': selectAll }">
          <v-checkbox
            v-model="selectAll"
            label="Select all"
            class="select-all-checkbox"
            color="primary"
            density="compact"
            hide-details
          ></v-checkbox>
        </v-list-item>
        <CalculationList
          :items="filteredCalculations"
          :search-query="searchQuery"
        ></CalculationList>
      </template>
      <template v-else>
        <v-list-item v-if="!fetchLoading">
          <v-list-item-title>
            No calculations available. Create a calculation to continue.
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-else><small> Loading... </small></v-list-item>
      </template>
    </v-list>
  </v-container>
</template>

<style scoped>
:deep(.v-list-item__prepend) {
  width: 24px;
}

.no-padding-list :deep(.v-list) {
  padding: 0 !important;
}

.selected-item,
.list-item:hover {
  background-color: #f4fcff;
}

.select-all-checkbox :deep(.v-label) {
  color: black;
  font-weight: bolder;
}
</style>
