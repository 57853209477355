import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { pinia } from './modules/pinia.ts'

import { AuthenticationManagement } from '@rhdhv/vue-authentication-middleware'
import { UserManagementApp } from '@rhdhv/vue-user-management'
import { ComponentLibrary, useEventsBus } from '@rhdhv/vue-component-library'

import vuetify from './plugins/vuetify'
import router from './router'
import App from './App.vue'
import 'vuetify/dist/vuetify.min.css'
import HeaderImage from '/header.jpeg'
import HeaderImageLazy from '/header_lazy.jpg'
import { useDjangoAuthStore } from './stores/authDjango'

import { useApi } from '@/api/useApi'
import apiUrls from '@/api/apiUrls'

const { api } = useApi()
const { bus } = useEventsBus()

pinia.use(({ store }) => {
  store.$api = api
  store.$apiUrls = apiUrls
})

const app = createApp(App).use(vuetify).use(pinia).use(router)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  environment: import.meta.env.MODE,
  tracePropagationTargets: ['*'],
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 10% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in cloud and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const authStore = useDjangoAuthStore(pinia)
app
  .use(AuthenticationManagement, {
    appTitle: 'Liquefaction',
    headerImage: HeaderImage,
    headerImageLazy: HeaderImageLazy,
    api: api,
    authStore: authStore,
    apiBaseUrl: import.meta.env.VITE_APP_API_BASE_URL,
    appFrontendBaseUrl: import.meta.env.VITE_APP_FRONTEND_BASE_URL
  })
  .provide('useAuthStore', authStore)
  .use(UserManagementApp, {
    authorizationStore: useAuthorizationStore(),
    authStore: authStore
  })

const projectStore = useProjectStore(pinia)
app.use(ComponentLibrary, { externalBus: bus, projectStore: projectStore })

app.mount('#app')
