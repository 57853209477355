import type { CalculationType } from '@/stores/type/calculation.type'

const headers = [
  {
    title: 'Name',
    value: 'name',
    width: '200px',
    cellProps: { width: '200px' },
    headerProps: { width: '200px' }
  },
  { title: 'Ground Water Table (In Situ) [m]', value: 'ground_water_table_insitu' },
  { title: 'Ground Water Table (Design) [m]', value: 'ground_water_table_design' },
  { title: 'Earthquake Magnitude (Mw)', value: 'earthquake_magnitude' },
  { title: 'Peak Ground Acceleration (PGA) [g]', value: 'max_acceleration' },
  { title: 'Return Period [year]', value: 'return_period' },
  { title: 'Fill Level [m]', value: 'fill_level', filter: 'has_fill' },
  { title: 'Unit weight [kN/m³]', value: 'unit_weight', filter: 'has_fill' },
  {
    title: 'Excavated Level [m]',
    value: 'excavated_level',
    filter: 'is_excavated'
  },
  { title: 'Load [kN/m²]', value: 'load', filter: 'has_load' },
  { title: 'Ic Limit', value: 'Ic_limit' },
  { title: 'Liquefaction Method', value: 'liquefaction_method' },
  { title: 'Liquefaction Settlement', value: 'liquefaction_settlement' },
  { title: 'Soil Classification', value: 'soil_classification' },
  { title: 'Relative Density', value: 'relative_density' }
]

export const filteredHeaders = (parameters: CalculationType, exclude: string[] = []) => {
  return headers.filter((header) => {
    if (exclude.includes(header.value)) {
      return
    }
    if (!header.filter) {
      return header
    }
    if (parameters[header.filter] && parameters.has_site_design_condition) {
      return header
    }
  })
}
