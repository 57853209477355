<script setup lang="ts">
import { computed, type ComputedRef, type PropType } from 'vue'
import type { GeometryType, GiPointType, PropertiesType } from '@/stores/type/giPoint.type'

const props = defineProps({
  item: { type: Object as PropType<GiPointType>, required: true },
  showSelect: { type: Boolean, default: false }
})

const properties: ComputedRef<PropertiesType> = computed(() => props.item.properties)
const geometry: ComputedRef<GeometryType> = computed(() => props.item.geometry)
const giType = computed(() => {
  return props.item?.properties.gi_type === 'BoringGEF' ||
    props.item?.properties.gi_type === 'BoringIMBRO'
    ? 'Borehole'
    : 'CPT'
})

const coordinateToFixed = (coordinate: number) => {
  if (!coordinate) return ''
  return coordinate.toFixed(3)
}
</script>
<template>
  <tr>
    <td v-if="props.showSelect">
      <slot name="select"></slot>
    </td>

    <td class="text-primary">{{ properties.location_id }}</td>
    <td>{{ properties.survey_company }}</td>
    <td>{{ properties.survey_datestring }}</td>
    <td>{{ giType }}</td>
    <td>{{ properties.end_depth }}</td>
    <td>{{ coordinateToFixed(geometry?.coordinates[2]) }}</td>
  </tr>
</template>
