<script setup lang="ts">
import type { CalculationType } from '@/stores/type/calculation.type'
import { computed, type PropType } from 'vue'
import { useCalculationStore } from '@/stores/calculation'

const props = defineProps({
  item: { type: Object as PropType<CalculationType>, required: true }
})

const store = useCalculationStore()

const { selectedItems } = storeToRefs(store)

const isSelected = computed({
  get: () => {
    return selectedItems.value?.includes(props.item) || false
  },
  set: () => {
    store.toggleSelectedItem(props.item?.id)
    store.toggleChildSelectedItems(props.item?.id)
  }
})
</script>

<template>
  <v-list-item-title class="d-flex align-center w-100">
    <v-row no-gutters class="w-100">
      <v-col cols="5" class="text-truncate d-flex">
        <div class="d-flex align-center g-1">
          <v-checkbox
            v-model="isSelected"
            color="primary"
            density="compact"
            hide-details
            @click.stop=""
          />
          <strong> {{ props.item.name }}</strong>
        </div>
      </v-col>
      <v-col cols="3" class="align-center d-flex">
        <v-row no-gutters>
          <v-col cols="6" class="align-center d-flex">
            <span>PGA: {{ props.item.max_acceleration }} </span>
          </v-col>
          <v-col cols="6" class="align-center d-flex">
            <span>Mw: {{ props.item.earthquake_magnitude }} </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" class="align-center d-flex">
        <v-row no-gutters v-if="props.item.has_site_design_condition">
          <v-col cols="3" class="align-center d-flex">
            {{ props.item.has_fill ? 'Fill' : '' }}
          </v-col>
          <v-col cols="5" class="align-center d-flex">
            <div>
              {{ props.item.is_excavated ? 'Excavated' : '' }}
            </div>
          </v-col>
          <v-col cols="4" class="align-center d-flex">
            <div>
              {{ props.item.has_load ? 'Loaded' : '' }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-list-item-title>
</template>
