<script setup lang="ts">
import { LIcon, LLayerGroup, LMarker, LTooltip } from '@vue-leaflet/vue-leaflet'
import { type PropType, ref, watch } from 'vue'

import { useGiPointStore } from '@/stores/giPoints'
import type { GiPointType } from '@/stores/type/giPoint.type'
import type { LatLngExpression } from 'leaflet'

const props = defineProps({
  item: {
    type: Object as PropType<GiPointType>,
    required: true
  },
  map: {
    type: Object,
    required: true
  }
})

const giPointStore = useGiPointStore()

const { currentItem: currentGIPoint } = storeToRefs(giPointStore)

const marker = ref()
const markerText = ref()
const emit = defineEmits(['fly-to-marker'])

const markerIcon = computed(() => {
  const gi_type = props.item?.properties?.gi_type
  return gi_type === 'BoringGEF' || gi_type === 'BoringIMBRO' ? 'mdi-circle' : 'mdi-triangle-down'
})

const isSelected = computed(() => {
  if (currentGIPoint.value?.id) {
    return currentGIPoint.value?.properties?.cpt.id === props.item?.properties.cpt.id
  }
  return false
})

function swapCoordinates(coordinates: number[]) {
  return [coordinates[1], coordinates[0]] as number[]
}

const latLngCoordinates = computed<LatLngExpression>(() => {
  return swapCoordinates(props.item.geometry.coordinates) as LatLngExpression
})

const onMarkersReady = () => {
  marker.value.leafletObject.on('click', () => {
    giPointStore.toggleSelectedItem(props.item?.id)
  })

  props.map.leafletObject.on('zoomend', () => {
    if (markerText.value) {
      if (props.map.leafletObject.getZoom() < 16) {
        props.map.leafletObject.removeLayer(markerText.value.leafletObject)
      } else {
        props.map.leafletObject.addLayer(markerText.value.leafletObject)
      }
    }

    if (marker.value) {
      if (props.map.leafletObject.getZoom() < 13) {
        props.map.leafletObject.removeLayer(marker.value.leafletObject)
      } else {
        props.map.leafletObject.addLayer(marker.value.leafletObject)
      }
    }
  })
}

watch(
  () => giPointStore.currentItem,
  () => {
    if (props.item.id === giPointStore.currentItem!.id) {
      emit('fly-to-marker', swapCoordinates(props.item.geometry.coordinates))
    }
  }
)
</script>

<template>
  <l-layer-group>
    <l-marker ref="marker" :lat-lng="latLngCoordinates" :key="item.id" @ready="onMarkersReady">
      <l-icon class-name="" :icon-size="[20, 20]">
        <v-icon size="medium" color="black" :class="isSelected ? 'selected' : 'selected-zone'">
          {{ markerIcon }}
        </v-icon>
      </l-icon>
      <l-tooltip>
        <div>
          <div>Total Length: {{ props.item.properties.end_depth }}</div>
          <div>Survey Company: {{ props.item.properties.survey_company }}</div>
          <div>Survey Date: {{ props.item.properties.survey_datestring }}</div>
        </div>
      </l-tooltip>
    </l-marker>
    <l-marker :lat-lng="latLngCoordinates" ref="markerText">
      <l-icon class-name="">
        <div class="text-below-marker">{{ props.item.properties.location_id }}</div>
      </l-icon>
    </l-marker>
  </l-layer-group>
</template>

<style scoped>
.text-below-marker {
  font-weight: bolder;
  position: absolute;
  margin-top: 15px;
  left: -45px;
  min-width: 100px;
  min-height: 20px;
  text-align: center;
  pointer-events: none;
}

.selected,
.selection-zone {
  left: -4px;
  width: 120%;
  height: 120%;
  position: absolute;
  border-radius: 100%;
  opacity: 0.7;
  background-color: #ff0000;
  pointer-events: all;
}

.selected::after,
.selection-zone::after {
  content: '';
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background-color: transparent;
  cursor: pointer;
  pointer-events: all;
}

.selection-zone {
  background-color: transparent;
}
</style>
