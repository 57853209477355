<script setup lang="ts">
import { computed, type PropType } from 'vue'

import GIPointBaseRow from '@/components/base/GIPointBaseRow.vue'

import type { GiPointType } from '@/stores/type/giPoint.type'

const props = defineProps({
  item: { type: Object as PropType<GiPointType>, required: true },
  showSelect: { type: Boolean, default: false }
})

const giPointStore = useGiPointStore()

const { selectedItems } = storeToRefs(giPointStore)

const isSelected = computed({
  get: () => {
    return selectedItems.value?.findIndex((item: any) => item.id === props.item.id) !== -1
  },
  set: () => {
    giPointStore.toggleSelectedItem(props.item?.id)
  }
})
</script>
<template>
  <GIPointBaseRow
    :item="item"
    :show-select="showSelect"
    :class="{
      'selected-item': isSelected
    }"
  >
    <template #select>
      <v-checkbox
        color="primary"
        density="compact"
        v-model="isSelected"
        hide-details
        class="ml-n1"
      ></v-checkbox>
    </template>
  </GIPointBaseRow>
</template>
