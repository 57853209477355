<script setup lang="ts">
import { computed, PropType } from 'vue'
import type { GiDataUploadCollectionType } from '@/stores/type/giPoint.type'
import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection'
import { useEventsBus } from '@rhdhv/vue-component-library'

const { emit: busEmit } = useEventsBus()
const props = defineProps({
  store: {
    type: Object,
    required: true
  },
  item: {
    type: Object as PropType<GiDataUploadCollectionType>,
    required: true
  }
})

const giDataUploadCollectionStore = useGiDataUploadCollectionStore()
const { selectedItems } = storeToRefs(giDataUploadCollectionStore)

const isSelected = computed({
  get: () => {
    return selectedItems.value?.includes(props.item) || false
  },
  set: () => {
    giDataUploadCollectionStore.toggleSelectedItem(props.item?.id)
    giDataUploadCollectionStore.toggleChildSelectedItems(props.item?.id)
  }
})
</script>

<template>
  <v-list-item-title class="d-flex justify-space-between">
    <div class="d-flex align-center justify-start g-2 ml-2">
      <v-checkbox
        color="primary"
        density="compact"
        hide-details
        v-model="isSelected"
        @click.stop=""
        class="ml-n1"
      ></v-checkbox>
      <strong> {{ props.item.name }}</strong>
    </div>
  </v-list-item-title>
</template>

<style scoped></style>
