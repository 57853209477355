<script lang="ts" setup>
import { getPrettyFromOptions } from '@/components/calculation/getPrettyFromOptions'
import { useCalculationStore } from '@/stores/calculation'

const props = defineProps({
  item: { type: Object, required: true }
})

const store = useCalculationStore()
</script>

<template>
  <tr v-if="!item.no_calculation">
    <td class="table-width">
      {{ item.name }}
    </td>
    <td class="table-width">
      {{ item.ground_water_table_insitu }}
    </td>
    <td class="table-width">
      {{ item.ground_water_table_design }}
    </td>
    <td class="table-width">
      {{ item.load }}
    </td>
    <td class="table-width">
      {{ item.max_acceleration }}
    </td>
    <td class="table-width">
      {{ item.return_period }}
    </td>
    <td class="table-width">
      {{ item.Ic_limit }}
    </td>
    <td class="table-width">
      {{ getPrettyFromOptions(item.liquefaction_method, store.fields, 'liquefaction_method') }}
    </td>
    <td class="table-width">
      {{
        getPrettyFromOptions(item.liquefaction_settlement, store.fields, 'liquefaction_settlement')
      }}
    </td>
    <td class="table-width">
      {{ getPrettyFromOptions(item.soil_classification, store.fields, 'soil_classification') }}
    </td>
    <td class="table-width">
      {{ getPrettyFromOptions(item.relative_density, store.fields, 'relative_density') }}
    </td>
  </tr>
  <tr v-else>
    <td class="table-width">
      {{ getGiDataUploadCollectionName(item.gi_data_upload_collection) }}
    </td>
    <td colspan="11" style="text-align: center">No calculation</td>
  </tr>
</template>
