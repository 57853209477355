import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { md3 } from 'vuetify/blueprints'

import { createVuetify } from 'vuetify'

const rhdhvLight = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#0186A7',
    secondary: '#0186A7',
    highlight: '#cce7ed',
    speckleColor: '#047EFB'
  }
}

export default createVuetify({
  blueprint: md3,
  theme: {
    defaultTheme: 'rhdhvLight',
    themes: {
      rhdhvLight
    }
  }
})
