<script setup lang="ts">
import { computed, defineEmits, toRef } from 'vue'
import { HelpTextTooltip } from '@rhdhv/vue-component-library'

const props = defineProps({
  modelValue: { type: Object, required: true },
  itemKey: { type: String, required: false },
  label: { type: String, required: false },
  field: { type: Object, required: true },
  hideDetails: { type: Boolean, required: false, default: false },
  center: { type: Boolean, required: false, default: false },
  noPadding: { type: Boolean, required: false, default: false },
  noLabel: { type: Boolean, required: false, default: false },
  noHelpText: { type: Boolean, required: false, default: false }
})

const emit = defineEmits<{
  update: []
  'update:modelValue': [Record<string, any>]
}>()

const modelValue = toRef(props, 'modelValue')
const field = toRef(props, 'field')
const itemKey = computed(() => {
  return props.itemKey ?? field.value.parameter
})

const value = computed({
  get() {
    let value = props.modelValue[itemKey.value]
    if (typeof value === 'number') {
      value = value.toFixed(2)
    }
    return value
  },
  set(value) {
    modelValue.value[itemKey.value] = value
    emit('update:modelValue', props.modelValue)
    emit('update')
  }
})

const noPaddingClass = computed(() => {
  return props.noPadding ? 'no-padding no-padding-append-inner' : ''
})

const fieldInputCenterClass = computed(() => {
  return props.center ? 'field-input-center' : ''
})

const label = computed(() => {
  return props.noLabel ? undefined : props.label ? props.label : props.field.label
})

const choices = computed(() => {
  return field.value.child ? field.value.child.choices : field.value.choices ?? []
})
</script>

<template>
  <div v-if="field" @click.stop>
    <v-select
      v-if="field.type === 'choice' || field.type === 'list'"
      :class="`${noPaddingClass} ${fieldInputCenterClass}`"
      :label="label"
      :multiple="field.type === 'list'"
      color="primary"
      :items="choices"
      item-value="value"
      item-title="display_name"
      :hide-details="hideDetails"
      v-model="value"
      v-bind="$attrs"
      @click.stop
    >
      <template v-slot:append-inner>
        <div class="pt-0">
          <HelpTextTooltip v-if="field.help_text && !noHelpText" :text="field.help_text" />
        </div>
      </template>
    </v-select>
  </div>
</template>

<style scoped>
.no-padding :deep(.v-field__input) {
  padding-top: 4px;
}

.no-padding-append-inner :deep(.v-field__append-inner) {
  padding-top: 4px;
}

.field-input-center :deep(.v-field__input) {
  text-align: center;
}

.field-input-center-append-inner :deep(.v-field__input) {
  text-align: center;
}
</style>
