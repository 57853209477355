import AppBaseStore from './baseStore'
import { defineStore } from 'pinia'

import type { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import API from '@/api/apiUrls'
import type { ProjectParameterType } from '@/stores/type/projectParameter.type'
import { useApi } from '@/api/useApi'

const { api } = useApi()

type State = BaseStoreType<ProjectParameterType>

const baseUrl = API.CORE.PROJECT_PARAMETER
const baseStore: BaseStoreType<ProjectParameterType> = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<ProjectParameterType> = baseStore.actions
export const useProjectParameterStore = defineStore({
  id: 'projectParameter',
  state: (): State => ({
    ...baseStore.state,
    loading: false,
    filterFields: ['project']
  }),
  actions: {
    ...baseStoreActions,
    resetAll(): void {
      this.$reset()
    }
  },
  getters: {
    ...baseStore.getters
  }
})
