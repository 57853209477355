import AppBaseStore from './baseStore'
import { defineStore } from 'pinia'
import type { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import type { GiDataUploadCollectionType, GiPointType } from '@/stores/type/giPoint.type'
import API from '@/api/apiUrls'
import { useApi } from '@/api/useApi'
import { useGiPointStore } from '@/stores/giPoints'

const { api } = useApi()

type State = BaseStoreType<GiDataUploadCollectionType>

const baseUrl = API.GI_DATA.GI_DATA_UPLOAD_COLLECTIONS.INDEX
const baseStore: BaseStoreType<GiDataUploadCollectionType> = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<GiDataUploadCollectionType> = baseStore.actions
export const useGiDataUploadCollectionStore = defineStore({
  id: 'giDataUploadCollection',
  state: (): State => ({
    ...baseStore.state,
    loading: false,
    showUploadDialog: false,
    filterFields: ['name']
  }),
  actions: {
    ...baseStoreActions,
    resetAll(): void {
      this.$reset()
    },
    toggleUploadDialog(item: GIPointCollection = null) {
      if (item) {
        this.currentItem = item
      }
      this.showUploadDialog = !this.showUploadDialog
    },
    toggleSelectedItem(id: number): void {
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index !== -1) {
        this.selectedItems.splice(index, 1)
      } else {
        const item = this.items.find((item) => item.id === id)
        this.selectedItems.push(item)
      }
    },
    toggleChildSelectedItems(id: number): void {
      const giPointStore = useGiPointStore()
      const item = this.items.find((item) => item.id === id)
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index !== -1) {
        giPointStore.selectAllItemsByCollectionId(item.id)
      } else {
        giPointStore.clearSelectedItemsByCollectionId(item.id)
      }
    },
    addSelectedItem(id: number): void {
      const item = this.items.find((item) => item.id === id)
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index === -1) {
        this.selectedItems.push(item)
      }
    },
    removeSelectedItem(id: number): void {
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index !== -1) {
        this.selectedItems.splice(index, 1)
      }
    },
    selectAllItems(): void {
      this.selectedItems = [...this.items]
      const giPointStore = useGiPointStore()
      this.selectedItems.forEach((item) => {
        giPointStore.selectAllItemsByCollectionId(item.id)
      })
    },
    clearSelectedItems(): void {
      this.selectedItems = []
      const giPointStore = useGiPointStore()
      giPointStore.clearSelectedItems()
    }
  },
  getters: {
    ...baseStore.getters,
    giDataUploadCollectionOnGiPoint:
      (state: State) =>
      (id: number): GiDataUploadCollectionType | undefined => {
        return state.items.find((item: GiDataUploadCollectionType) => {
          if (item.id === id) {
            return item
          }
        })
      },
    hasCalculationCPTRelatedToSelectedItems: (state: State) => (): boolean => {
      const giPointStore = useGiPointStore()
      const giPoints: GiPointType[] = []
      state.selectedItems.forEach((item) => {
        giPoints.push(...item.gipoint_set.features)
      })
      return giPointStore.hasCalculationCPTRelatedToSelectedItems(giPoints)
    }
  }
})
