import AppBaseStore from './baseStore'
import { defineStore } from 'pinia'

import type { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import API from '@/api/apiUrls'
import type { CPTType } from '@/stores/type/cpt.type'
import { useApi } from '@/api/useApi'

const { api } = useApi()

type State = BaseStoreType<CPTType>

const baseUrl = API.CPT.BASE
const baseStore: BaseStoreType<CPTType> = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<CPTType> = baseStore.actions
export const useCPTStore = defineStore({
  id: 'cpt',
  state: (): State => ({
    ...baseStore.state
  }),
  actions: {
    ...baseStoreActions,
    resetAll() {
      this.$reset()
    },
    async fetchOrGetCPT(cptId: number): Promise<void> {
      const cpt = this.items.find((cpt) => cpt.id === cptId)
      if (!cpt) {
        const response = await this.fetchItem(cptId)
        if (response) {
          this.addItems([response])
          this.setCurrentItem(response)
        }
      } else {
        this.setCurrentItem(cpt)
      }
    }
  },
  getters: {
    ...baseStore.getters
  }
})
