<script setup lang="ts">
import Plotly, { type Layout, type PlotData } from 'plotly.js-basic-dist'
import { computed, type ComputedRef, onMounted, type PropType, ref, watch } from 'vue'

const props = defineProps({
  data: { type: Object as PropType<PlotData[]>, required: true },
  maxXAxis: { type: Number, required: false, default: 20 },
  minXAxis: { type: Number, required: false, default: 0 },
  minYAxis: { type: Number, required: false, default: 0 },
  maxYAxis: { type: Number, required: false, default: 18 },
  xAxisTitle: { type: String, required: false, default: 'X-axis Label' },
  yAxisTitle: { type: String, required: false, default: 'elevation [m]' },
  title: { type: String, required: false, default: 'CPT Chart' },
  lineNumbers: { type: Number, required: false, default: 1 }
})
const chartRef = ref(null)
const layout: ComputedRef<Partial<Layout>> = computed(() => {
  return {
    dragmode: 'pan',
    autosize: true,
    margin: { l: 35, r: 15, b: 80, t: 40, pad: 4 },
    showlegend: false,
    title: props.title,
    titlefont: { size: 14 },
    xaxis: {
      title: props.xAxisTitle,
      range: [props.minXAxis, props.maxXAxis],
      titlefont: { size: 12 },
      nticks: 10
    },
    yaxis: {
      title: props.yAxisTitle,
      range: [props.minYAxis, props.maxYAxis],
      titlefont: { size: 12 },
      nticks: 10
    }
  }
})

useResizeObserver(chartRef, () => relayoutChart())

function createChart() {
  if (props.data && chartRef.value) {
    const data = props.data.filter((data) => data.line.color != 'transparent')
    Plotly.newPlot(chartRef.value, data, layout.value, {
      responsive: true
    })
  }
}

const relayoutChart = () => {
  if (chartRef.value) {
    Plotly.relayout(chartRef.value, layout.value)
  }
}

watch(
  () => props.maxXAxis,
  () => {
    createChart()
  }
)
watch(
  () => props.maxYAxis,
  () => {
    createChart()
  }
)
watch(
  () => props.minXAxis,
  () => {
    createChart()
  }
)

watch(
  () => props.minYAxis,
  () => {
    createChart()
  }
)

watch(
  () => props.data?.length,
  () => {
    createChart()
  }
)

watch(
  () => props.lineNumbers,
  () => {
    createChart()
  }
)

onMounted(async () => {
  createChart()
})
</script>

<template>
  <div ref="chartRef" class="fill-height"></div>
</template>
