import BaseStore from '@rhdhv/vue-basestore'
import _ from 'lodash'

class AppBaseStore extends BaseStore {
  constructor(r: string, a: any, l = null, i: string = 'id') {
    super(r, a, l, i)
    this.state = {
      ...this.state,
      error: false,
      options: {},
      a: a,
      r: r,
      i: i
    }
    this.actions = {
      ...this.actions,
      fetchOptions: this.fetchOptions,
      postOrUpdate: this.postOrUpdate,
      setDeleteItem: this.setDeleteItem,
      update: this.update,
      addToProperty: this.addToProperty,
      fetchItemsAdd: this.fetchItemsAdd
    }
    this.getters = {
      ...this.getters,
      fieldsList(state) {
        if (state.options.actions) {
          const writableFields = Object.entries(state.options.actions.POST)?.filter(
            ([, value]: [string, any]) => !value.read_only
          )
          return writableFields?.map((item: any) => {
            item[1].parameter = item[0]
            return item[1]
          })
        } else {
          return []
        }
      },
      getItem:
        (state) =>
        (id: number): any => {
          const item = state.items.find((item) => item.id === id)
          return item ? item : 0
        },
      fieldsFiltered(state) {
        return this.fieldsList?.filter((field) => state.filterFields.includes(field.parameter))
      },
      fields(state) {
        if (state.options.actions?.POST) {
          const writableFields = state.options.actions.POST
          Object.entries(writableFields).forEach(([key, value]) => {
            value.parameter = key
          })
          return writableFields
        }
        return state.options.actions?.POST
      },
      itemsAvailable(state) {
        return state.items ? state.items.length > 0 : false
      },
      optionsAvailable(state) {
        return !_.isEmpty(state.options)
      }
    }
  }

  async fetchOptions() {
    this.options = await this.a.service(this.r).options()
  }

  async fetchItemsAdd(filter = {}) {
    this.fetchLoading = true
    try {
      const response = await this.a.service(this.r).find({
        query: filter
      })
      this.items.push(...response.data)
    } catch (e) {
      this.setSnackbarMessage('Fetch items failed.', 'error')
      console.error(e)
      this.fetchLoading = false
      return false
    }
    this.fetchLoading = false
    return this.items
  }

  async postOrUpdate(item = null) {
    return this.currentItem.id
      ? await this.update(item, this.currentItem.id)
      : await this.create(item)
  }

  // TODO should be cleaned up better
  async update(item = null, id = null) {
    this.writeLoading = true
    const updateItem = item ? item : this.currentItem
    let responseData
    const idNumber = id ? id : updateItem[this.i]
    try {
      responseData = await this.a.service(this.r).patch(idNumber, updateItem)
      const index = this.items.map((item) => item[this.i]).indexOf(responseData[this.i])
      this.items[index] = responseData
    } catch (e) {
      this.writeLoading = false
      return false
    }
    this.writeLoading = false
    return responseData
  }

  // TODO refactor this
  addToProperty(value, property) {
    if (this.items[property]) {
      const ids = this.items[property].map((item) => item.id)
      const onlyNewItems = value.filter((item) => !ids.includes(item.id))
      this.items[property].push(...onlyNewItems)
    } else {
      this.items[property] = value
    }
  }

  setDeleteItem(e) {
    this.deleteItem = e
  }
}

export default AppBaseStore
