<script setup lang="ts">
import ChoiceField from '../form/ChoiceField.vue'
import { TextField, CheckboxField } from '@rhdhv/vue-component-library'
import type { StoreGeneric } from 'pinia'
import { type PropType, toRef } from 'vue'
import { BOULANGER_IDRISS_PROB, MOSS_PROB } from '@/stores/type/calculationCPTDataPoint.type'

const props = defineProps({
  store: { type: Object as PropType<StoreGeneric>, required: true },
  item: { type: Object, required: true },
  readonly: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false }
})

const store = props.store
const item = toRef(props, 'item')

const field = computed(() => {
  return store.fields
})

async function updateItem(): Promise<void> {
  if (props.readonly) {
    return
  }
  await store.setParameter(
    props.item,
    'has_site_design_condition',
    !props.item.has_site_design_condition
  )
  await store.update()
}

const showCRR = computed(() => {
  return (
    props.item.liquefaction_method.includes(BOULANGER_IDRISS_PROB) ||
    props.item.liquefaction_method.includes(MOSS_PROB)
  )
})
</script>

<template>
  <v-list-item>
    <v-list-item-title> Seismicity input</v-list-item-title>
  </v-list-item>
  <v-list-item>
    <v-row no-gutters>
      <v-col cols="6" class="pr-2">
        <TextField
          :field="field['earthquake_magnitude']"
          :modelValue="item"
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
          warning
          required
          :warning-rules="[
            {
              min_value: 5.5,
              message: `Earthquake magnitude (Mw) falls out of range (5.5 < Mw < 8.5), this may lead to inaccurate results`
            },
            {
              max_value: 8.5,
              message: `Earthquake magnitude (Mw) falls out of range (5.5 < Mw < 8.5), this may lead to inaccurate results`
            }
          ]"
        ></TextField>
      </v-col>
      <v-col cols="6">
        <TextField
          :field="field['max_acceleration']"
          :modelValue="item"
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
          required
        ></TextField>
      </v-col>
    </v-row>
  </v-list-item>
  <v-list-item>
    <v-list-item-title>Calculation options</v-list-item-title>
  </v-list-item>
  <v-list-item>
    <v-row no-gutters>
      <v-col cols="6" class="pr-2">
        <ChoiceField
          :field="field['soil_classification']"
          :model-value="item"
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
        ></ChoiceField>
      </v-col>
      <v-col cols="6">
        <ChoiceField
          :field="field['soil_unit_weight']"
          :model-value="item"
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
        ></ChoiceField>
      </v-col>
      <v-col class="pr-2" cols="6">
        <ChoiceField
          :field="field['liquefaction_method']"
          :model-value="item"
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
        ></ChoiceField>
      </v-col>
      <v-col cols="6">
        <ChoiceField
          v-if="showCRR"
          :field="field['CRR_limit']"
          :model-value="item"
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
        ></ChoiceField>
      </v-col>

      <v-col class="pr-2" cols="6">
        <ChoiceField
          :field="field['relative_density']"
          :model-value="item"
          hide-details
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
        ></ChoiceField>
      </v-col>
      <v-col cols="6">
        <ChoiceField
          :field="field['liquefaction_settlement']"
          :model-value="item"
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
        ></ChoiceField>
      </v-col>

      <v-col cols="6" class="pr-2">
        <ChoiceField
          :field="field['Ic_cutoff']"
          :model-value="item"
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
        ></ChoiceField>
      </v-col>

      <v-col cols="6">
        <TextField
          v-if="item['Ic_cutoff']"
          :field="field['Ic_limit']"
          :model-value="item"
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
          warning
          :warning-rules="[
            {
              min_value: 2.6,
              message: `Please be warned when changing the default Ic limit (2.6)`
            },
            {
              max_value: 2.6,
              message: `Please be warned when changing the default Ic limit (2.6)`
            }
          ]"
        ></TextField>
      </v-col>
    </v-row>
  </v-list-item>
  <v-list-item class="pt-4">
    <v-list-item-title>Ground water level [elevation]</v-list-item-title>
  </v-list-item>

  <v-list-item>
    <v-row no-gutters>
      <v-col cols="6" class="pr-2">
        <TextField
          :field="field['ground_water_table_insitu']"
          :model-value="item"
          @update="store.update()"
          required
          :readonly="readonly"
          :disabled="disabled"
        ></TextField>
      </v-col>
      <v-col cols="6">
        <TextField
          :field="field['ground_water_table_design']"
          :step="0.1"
          :model-value="item"
          required
          @update="store.update()"
          :readonly="readonly"
          :disabled="disabled"
        ></TextField>
      </v-col>
    </v-row>
  </v-list-item>

  <v-list-item>
    <v-list-item-title>
      <div
        class="d-inline pr-2"
        style="border-right-style: solid; border-right-width: 1px; border-right-color: #dadada"
      >
        Site conditions
      </div>
      <v-divider class="pl-2" :vertical="true"></v-divider>
      <v-btn-toggle
        density="compact"
        :model-value="item.has_site_design_condition"
        @update:model-value="updateItem()"
        color="primary"
        group
      >
        <v-btn
          class="pl-4 text-none"
          :variant="!item.has_site_design_condition ? 'elevated' : 'tonal'"
          slim
          density="compact"
          :value="false"
          :append-icon="!item.has_site_design_condition ? 'mdi-check' : 'empty'"
          :readonly="readonly"
          :disabled="disabled"
        >
          Native condition
        </v-btn>
        <v-btn
          slim
          class="pl-4 text-none"
          :variant="item.has_site_design_condition ? 'elevated' : 'tonal'"
          density="compact"
          :value="true"
          :append-icon="item.has_site_design_condition ? 'mdi-check' : 'empty'"
          :readonly="readonly"
          :disabled="disabled"
        >
          Design condition
        </v-btn>
      </v-btn-toggle>
    </v-list-item-title>
  </v-list-item>
  <v-list-item v-if="item.has_site_design_condition">
    <v-row no-gutters class="mt-3">
      <v-col>
        <CheckboxField
          @update="store.update()"
          :field="field['has_fill']"
          :model-value="item"
          :readonly="readonly"
          :disabled="disabled"
        />
      </v-col>
      <template v-if="item.has_fill">
        <v-col cols="4" class="pr-2">
          <TextField
            :field="field['fill_level']"
            :model-value="item"
            @update="store.update()"
            :readonly="readonly"
            :disabled="disabled"
          ></TextField>
        </v-col>
        <v-col cols="4">
          <TextField
            :field="field['unit_weight']"
            :model-value="item"
            @update="store.update()"
            :readonly="readonly"
            :disabled="disabled"
          ></TextField>
        </v-col>
      </template>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <CheckboxField
          @update="store.update()"
          :field="field['is_excavated']"
          :model-value="item"
          :readonly="readonly"
          :disabled="disabled"
        />
      </v-col>
      <template v-if="item.is_excavated">
        <v-col cols="4">
          <TextField
            :store="store"
            :field="field['excavated_level']"
            :model-value="item"
            @update="store.update()"
            :readonly="readonly"
            :disabled="disabled"
          ></TextField>
        </v-col>
      </template>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <CheckboxField
          @update="store.update()"
          :field="field['has_load']"
          :model-value="item"
          :readonly="readonly"
          :disabled="disabled"
        />
      </v-col>
      <template v-if="item.has_load">
        <v-col cols="4">
          <TextField
            :field="field['load']"
            :model-value="item"
            @update="store.update()"
            :readonly="readonly"
            :disabled="disabled"
          ></TextField>
        </v-col>
      </template>
    </v-row>
  </v-list-item>
  <v-list-item v-else>
    <v-card-subtitle class="pa-0 pl-0">
      No settings required. Choose 'design condition' above to set values.
    </v-card-subtitle>
  </v-list-item>
</template>
<style scoped>
.v-list-item-title {
  font-weight: bold;
}
</style>
