<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  items: {
    type: Array,
    required: true
  },
  isSelectedTable: {
    type: Boolean,
    required: false
  },
  showSelect: { type: Boolean, default: false }
})

const headers = [
  { title: 'Name', key: 'properties.location_id' },
  { title: 'Survey Company', key: 'properties.survey_company' },
  { title: 'Survey Date', key: 'properties.survey_datestring' },
  { title: 'GI Type', key: 'properties.gi_type' },
  { title: 'End Depth', key: 'properties.end_depth' },
  { title: 'Z Coordinates', key: 'geometry.coordinates' }
]
const tableHeaders = computed(() => {
  return props.showSelect
    ? [
        {
          title: '',
          key: '',
          sortable: false
        },
        ...headers
      ]
    : headers
})
</script>

<template>
  <v-data-table
    :items="items"
    :class="{ 'selected-item': isSelectedTable }"
    :headers="tableHeaders"
    :hover="true"
    items-per-page="-1"
    no-data-text="No data available."
    class="row-pointer"
    density="compact"
  >
    <template v-slot:item="{ item }">
      <slot name="item" :item="item"></slot>
    </template>
    <template #bottom></template>
  </v-data-table>
</template>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

:deep(.v-table__wrapper) table tbody tr:hover {
  background-color: #f4fcff;
}
</style>
