<script setup lang="ts">
import { computed } from 'vue'

import { AddUserDialog, UserManagementTable } from '@rhdhv/vue-user-management'
import DeleteDialog from '@/components/dialog/DeleteDialog.vue'
import { useProjectStore } from '@/stores/project'
import RHDHVDrawer from '@/components/rhdhv-drawer/RHDHVDrawer.vue'

const projectUserStore = useProjectUserStore()
const authorizationStore = useAuthorizationStore()

const canAdd = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['add_projectuser']
  })
})

const canEdit = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['change_projectuser']
  })
})
</script>

<template>
  <RHDHVDrawer state-name="userManagementDrawer" :temporary="true">
    <v-list-item class="mt-2">
      <h3 class="font-weight-medium">
        User Management
        <v-tooltip v-if="canAdd" text="Invite people to project">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              @click="projectUserStore.toggleAddUserDialog()"
              class="ml-3 mb-1 text-none"
              prepend-icon="mdi-plus"
              color="primary"
              flat
              size="small"
            >
              Add
            </v-btn>
          </template>
        </v-tooltip>
      </h3>
      <v-list-item-subtitle v-if="canAdd">
        Please configure the permissions for users within this project, add a new user via the
        <v-btn
          class="mx-1 cursor-default text-none"
          :ripple="false"
          prepend-icon="mdi-plus"
          size="x-small"
          color="primary"
          flat
          readonly
        >
          Add
        </v-btn>
        button.
      </v-list-item-subtitle>
      <v-list-item-subtitle v-else> Manage access to the project</v-list-item-subtitle>
    </v-list-item>
    <v-divider class="mt-2"></v-divider>
    <v-lazy>
      <UserManagementTable
        :delete-dialog="DeleteDialog"
        :can-edit="!!canEdit"
        auth-role="ProjectUser"
        :auth-model-user-store="projectUserStore"
        auth-model-key="project"
      />
    </v-lazy>
  </RHDHVDrawer>
  <AddUserDialog
    :auth-model-user-store="projectUserStore"
    :auth-model-store="useProjectStore()"
    auth-model-key="project"
  />
</template>
