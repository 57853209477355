import AppBaseStore from './baseStore'
import { defineStore } from 'pinia'

import { useEventsBus } from '@rhdhv/vue-component-library'

import type { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import type { CalculationType } from '@/stores/type/calculation.type.ts'
import API from '@/api/apiUrls'
import { useApi } from '@/api/useApi'
import { useCalculationCPTStore } from '@/stores/calculationCPT'

const { emit } = useEventsBus()
const { api } = useApi()

type State = BaseStoreType<CalculationType> & {
  loading: boolean
  createDialog: boolean
  filterFields: string[]
  notReadyItems: number[]
}

const baseUrl = API.CALCULATION.BASE
const processUrl = API.CALCULATION.PROCESS
const baseStore: BaseStoreType<CalculationType> = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<CalculationType> = baseStore.actions

type CalculationWithProcess = {
  calculation: CalculationType
  process: string
}

export const useCalculationStore = defineStore({
  id: 'calculation',
  state: (): State => ({
    ...baseStore.state,
    createDialog: false,
    loading: false,
    filterFields: ['name'],
    notReadyItems: []
  }),
  actions: {
    ...baseStoreActions,
    resetAll(): void {
      this.$reset()
    },
    toggleCreateDialog(
      item: CalculationType | null | undefined = undefined,
      value: boolean | undefined = undefined
    ): void {
      if (typeof item !== 'undefined') {
        this.currentItem = item
      }
      this.createDialog = value ?? !this.duplicateDialog
    },
    toggleNotReadyItem(item: number) {
      const index = this.notReadyItems.indexOf(item)
      if (index === -1) {
        this.notReadyItems.push(item)
      } else {
        this.notReadyItems.splice(index, 1)
      }
    },
    pollProcess(processId: string, calculationId: number): void {
      const url = processUrl + processId + '/'
      const interval = setInterval(async () => {
        const response = await api.service(url).get()
        if (response.status === 'SUCCEEDED') {
          clearInterval(interval)
          await this.updateItem(calculationId)
          const message = {
            status: 201,
            data: [],
            // using POST method instead of GET method to show the download snackbar
            method: 'POST',
            statusText: 'Calculation was successfully completed'
          }
          emit('success', message)
          this.toggleNotReadyItem(calculationId)
        } else if (response.status === 'FAILED') {
          clearInterval(interval)
          const calculation = this.getCalculationById(calculationId)
          this.removeItem(calculation)
          this.setCurrentItem({})
          const message = {
            status: 400,
            data: [],
            method: 'POST',
            statusText: 'Calculation was not successful'
          }
          emit('error', message)
          this.toggleNotReadyItem(calculationId)
        }
      }, 3000)
    },
    async createCalculation(createItem): Promise<void> {
      this.writeLoading = true
      const url = baseUrl + 'run_task/'
      const responseData: CalculationWithProcess = await api.service(url).create(createItem)
      const calculation = responseData.calculation
      this.toggleNotReadyItem(calculation.id)
      this.pollProcess(responseData.process, calculation.id)
      this.addItems([calculation])
      const message = {
        status: 202,
        data: [],
        method: 'POST',
        statusText: 'A background task is running to generate your calculation results.'
      }
      emit('success', message)
      this.writeLoading = false
    },
    async updateItem(updateItemId: number): void {
      this.writeLoading = true
      const calculation = await this.fetchItem(updateItemId)
      this.setCurrentItem({})
      this.items = this.items.map((item) => {
        if (item.id === updateItemId) {
          return calculation
        }
        return item
      })
      this.writeLoading = false
    },
    removeItemsByCollectionId(collectionId: number): void {
      const removeItems = this.items.filter(
        (item) => item.gi_data_upload_collection === collectionId
      )
      removeItems.forEach((item) => {
        this.removeItem(item)
      })
    },
    toggleSelectedItem(id: number): void {
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index !== -1) {
        this.selectedItems.splice(index, 1)
      } else {
        const item = this.items.find((item) => item.id === id)
        this.selectedItems.push(item)
      }
    },
    toggleChildSelectedItems(id: number): void {
      const calculationCPTStore = useCalculationCPTStore()
      const item = this.items.find((item) => item.id === id)
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index !== -1) {
        calculationCPTStore.selectAllItemsByCalculationId(item.id)
      } else {
        calculationCPTStore.clearSelectedItemsByCalculationId(item.id)
      }
    },
    addSelectedItem(id: number): void {
      const item = this.items.find((item) => item.id === id)
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index === -1) {
        this.selectedItems.push(item)
      }
    },
    removeSelectedItem(id: number): void {
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index !== -1) {
        this.selectedItems.splice(index, 1)
      }
    },
    selectAllItems(): void {
      this.selectedItems = [...this.items]
      const calculationCPTStore = useCalculationCPTStore()
      this.selectedItems.forEach((item) => {
        calculationCPTStore.selectAllItemsByCalculationId(item.id)
      })
    },
    clearSelectedItems(): void {
      this.selectedItems = []
      const calculationCPTStore = useCalculationCPTStore()
      calculationCPTStore.clearSelectedItems()
    }
  },
  getters: {
    ...baseStore.getters,
    getCalculationById: (state: State) => (id: number) => {
      return state.items.find((item) => item.id === id) ?? null
    }
  }
})
