<script lang="ts" setup>
import { computed } from 'vue'

const authorizationStore = useAuthorizationStore()
const calculationStore = useCalculationStore()
const calculationCPTStore = useCalculationCPTStore()

const { selectedItems: selectedCalculations } = storeToRefs(calculationStore)
const { selectedItems: selectedCalculationCPTs } = storeToRefs(calculationCPTStore)

const canDeleteCalculation = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['delete_calculation']
  })
})

const canDeleteCalculationCPT = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['delete_calculationcpt']
  })
})

const disableDeleteButton = computed(() => {
  return (
    (!selectedCalculations.value?.length && !selectedCalculationCPTs.value?.length) ||
    !canDeleteCalculationCPT.value ||
    !canDeleteCalculation.value
  )
})
</script>

<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
        color="primary"
        class="text-none my-1"
        variant="tonal"
        prepend-icon="mdi-delete-outline"
        append-icon="mdi-menu-down"
        v-bind="props"
        :disabled="disableDeleteButton"
        >Delete
      </v-btn>
    </template>
    <v-list class="py-0">
      <v-list-item
        v-if="selectedCalculations?.length > 0 && canDeleteCalculation"
        density="compact"
        v-ripple
        @click="calculationStore.toggleDeleteDialog()"
      >
        <v-list-item-title v-ripple class="cursor-pointer"
          >Delete Selected Calculations
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="selectedCalculationCPTs?.length > 0 && canDeleteCalculationCPT"
        density="compact"
        v-ripple
        @click="calculationCPTStore.toggleDeleteDialog()"
      >
        <v-list-item-title class="cursor-pointer"
          >Delete Selected CalculationCPTs
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
