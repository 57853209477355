<script setup lang="ts">
import { inject, ref, computed } from 'vue'
import { type StoreGeneric } from 'pinia'
import {
  useResizeableElement,
  vDrawerElementGetter
} from '@/components/rhdhv-drawer/utils/useResizeableElement'

type ResizerType = 'left' | 'right' | 'top' | 'bottom'

const store = inject('projectStore') as StoreGeneric

const props = defineProps({
  stateName: {
    type: String,
    required: true
  },
  resizer: {
    type: Array<ResizerType>,
    required: false,
    default: ['right']
  }
})
const drawerRef = ref<HTMLElement | null>(null)

const stateNameDrawer = computed<string>(() => {
  return props.stateName
})

const drawer = computed(() => {
  return store[stateNameDrawer.value]
})

const drawerResizer = computed<ResizerType[]>(() => {
  return props.resizer
})

const shown = computed<boolean>(() => {
  return drawer.value.shown
})

const BORDER_WIDTH = '8px'

const resizeBorder = computed<{ [key: string]: string }>(() => {
  let style: { [key: string]: string } = {}
  if (drawerResizer.value.includes('left')) {
    style['border-left-width'] = BORDER_WIDTH
  }
  if (drawerResizer.value.includes('right')) {
    style['border-right-width'] = BORDER_WIDTH
  }
  if (drawerResizer.value.includes('top')) {
    style['border-top-width'] = BORDER_WIDTH
  }
  if (drawerResizer.value.includes('bottom')) {
    style['border-bottom-width'] = BORDER_WIDTH
  }
  return style
})

useResizeableElement({
  ref: drawerRef,
  store: store,
  widthProperty: stateNameDrawer.value,
  elementGetter: vDrawerElementGetter,
  resizer: drawerResizer.value
})
</script>

<template>
  <v-navigation-drawer
    ref="drawerRef"
    v-bind="$attrs"
    :scrim="false"
    :style="resizeBorder"
    :model-value="shown"
    :width="drawer.width"
    :class="[drawer.shown ? 'resize-handler' : 'hidden']"
  >
    <slot></slot>
  </v-navigation-drawer>
</template>

<style scoped>
.resize-handler {
  z-index: 0;
  box-shadow: none;
}

.hidden {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease;
}
</style>
