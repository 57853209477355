import AppBaseStore from './baseStore'
import type { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import { DrawerType, ProjectType } from '@/stores/type/project.type'
import API from '@/api/apiUrls'
import { useApi } from '@/api/useApi'
import type { ProjectUser } from '@/stores/type/projectUser.type'
import { useAuthorizationStore } from '@/stores/authorization'

const { api } = useApi()

type State = BaseStoreType<ProjectType>

const baseUrl = API.CORE.PROJECTS
const baseStore = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<ProjectType> = baseStore.actions

const DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH = 700
const DEFAULT_DRAWER_WIDTH = 600
const DEFAULT_RESULT_DRAWER_WIDTH = 800

export const useProjectStore = defineStore({
  id: 'projects',
  state: (): State => ({
    ...baseStore.state,
    servicePermissionProjects: [],
    filterFields: ['name', 'location', 'client', 'description'],
    drawer: {
      shown: false,
      width: 250
    },
    calculationDrawer: {
      shown: false,
      width: DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH,
      minWidth: 300
    },
    giDrawer: {
      shown: true,
      width: DEFAULT_DATA_MANAGEMENT_DRAWER_WIDTH,
      minWidth: 300
    },
    resultDrawer: {
      shown: false,
      width: DEFAULT_RESULT_DRAWER_WIDTH,
      minWidth: 300
    },
    userManagementDrawer: {
      shown: false,
      width: DEFAULT_DRAWER_WIDTH,
      minWidth: 300
    },
    projectParameterDrawer: {
      shown: false,
      width: DEFAULT_DRAWER_WIDTH,
      minWidth: 300
    }
  }),
  actions: {
    ...baseStoreActions,
    resetAll(): void {
      this.$reset()
    },
    resetDrawerWidth(drawer: DataManagementDrawer | ChartSidebar): void {
      drawer.width = DEFAULT_DRAWER_WIDTH
    },
    toggleDrawer(): void {
      this.drawer.shown = !this.drawer.shown
    },
    toggleResultDrawer(bool: boolean = true): void {
      this.resultDrawer.shown = !bool ? false : !this.resultDrawer.shown
    },
    toggleDrawerType(drawerType: DrawerType): void {
      const drawerTypes: DrawerType[] = [
        'calculationDrawer',
        'giDrawer',
        'resultDrawer',
        'userManagementDrawer',
        'projectParameterDrawer'
      ]
      for (const type of drawerTypes) {
        if (type === drawerType) {
          this[type].shown = !this[type].shown
        } else {
          this[type].shown = false
        }
      }
    },
    async servicePermissionItems() {
      const projectUserStore = useProjectUserStore()
      const authStore = useDjangoAuthStore()
      const authorizationStore = useAuthorizationStore()

      const userId = authStore.user?.id
      await projectUserStore.fetchItems({ user: userId })

      const roleItems = projectUserStore.items.map((item: ProjectUser) => {
        return {
          role: item.role,
          id: item.project
        }
      })
      this.servicePermissionProjects = await authorizationStore.getServicePermissionByRole({
        roleItems: roleItems,
        permission: ['change_project', 'delete_project']
      })
    }
  },
  getters: {
    ...baseStore.getters,
    getCurrentProject(state): ProjectType | null {
      return state.currentItem ? state.currentItem : null
    },
    getChangePermission: (state) => (id: number) => {
      if (!state.servicePermissionProjects.length) return true
      const permissions =
        state.servicePermissionProjects.find((item) => item.id === id)?.permissions ?? []
      return permissions.includes('change')
    },
    getDeletePermission: (state) => (id: number) => {
      if (!state.servicePermissionProjects.length) return true
      const permissions =
        state.servicePermissionProjects.find((item) => item.id === id)?.permissions ?? []
      return permissions.includes('delete')
    }
  }
})
