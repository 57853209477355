<script setup lang="ts">
import { useProjectStore } from '@/stores/project'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import type { DrawerType } from '@/stores/type/project.type'

const store = useProjectStore()

const { currentItem: currentProject, drawer } = storeToRefs(store)

const navigationItems = ref<
  {
    title: string
    colTitle?: string
    icon: string
    event: DrawerType
  }[]
>([
  {
    title: 'GI Data',
    colTitle: 'GI Data',
    icon: 'mdi-landslide',
    event: 'giDrawer'
  },
  {
    title: 'Calculation management',
    colTitle: 'Calc.',
    icon: 'mdi-table',
    event: 'calculationDrawer'
  },
  {
    title: 'Project parameters',
    colTitle: 'Project par.',
    icon: 'mdi-window-shutter-cog',
    event: 'projectParameterDrawer'
  },
  {
    title: 'User management',
    colTitle: 'Users',
    icon: 'mdi-account-multiple',
    event: 'userManagementDrawer'
  }
])

const activeDrawer = computed(() => {
  const drawers = [
    'giDrawer',
    'calculationDrawer',
    'userManagementDrawer',
    'projectParameterDrawer'
  ]
  for (const drawer of drawers) {
    if (store[drawer].shown) {
      return drawer
    }
  }
  return ''
})
</script>

<template>
  <v-navigation-drawer :width="drawer.shown ? drawer.width : 60" :absolute="true">
    <v-list class="py-0">
      <v-list-item class="w-100" v-if="drawer.shown">
        <v-tooltip :text="currentProject?.name">
          <template v-slot:activator="{ props }">
            <v-list-item-title v-bind="props" class="font-weight-bold text-h6">
              {{ currentProject?.name }}
            </v-list-item-title>
          </template>
        </v-tooltip>
        <div class="d-flex ga-2 align-center">
          <v-chip class="pl-1" size="small" label>
            <v-icon class="mr-1" small>mdi-map-marker</v-icon>
            {{ currentProject?.location }}
          </v-chip>
          <v-list-item-subtitle>
            {{ currentProject?.client }}
          </v-list-item-subtitle>
        </div>
      </v-list-item>
      <v-divider v-if="drawer.shown" style="margin-top: 10px"></v-divider>
      <div class="d-flex flex-column">
        <v-list-item
          v-for="item in navigationItems"
          :key="item.title"
          @click="store.toggleDrawerType(item.event)"
          class="drawer-item py-3"
          :class="{ 'active-drawer': activeDrawer === item.event }"
        >
          <template v-slot:prepend>
            <div class="d-flex flex-column justify-center align-start">
              <v-icon style="opacity: 1" :icon="item.icon" color="primary" size="large"></v-icon>
              <div
                class="text-wrap text-center list-item-title-collapse ml-n3"
                v-if="!drawer.shown"
              >
                {{ item.colTitle }}
              </div>
            </div>
          </template>

          <v-list-item-title class="ml-4" v-if="drawer.shown">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<style scoped>
.active-drawer {
  background-color: #e9f4f8;
}

.drawer-item:hover {
  background-color: #f4fcff;
}

.list-item-title-collapse {
  font-size: 12px;
  width: 50px;
  word-wrap: break-word;
}
</style>
