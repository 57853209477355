import AppBaseStore from './baseStore'
import { defineStore } from 'pinia'

import type { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import type { CalculationCPTType } from '@/stores/type/calculationCPT.type.ts'
import API from '@/api/apiUrls'
import { useApi } from '@/api/useApi'
import { useCalculationStore } from '@/stores/calculation'
import type { GiPointType } from '@/stores/type/giPoint.type'
import type { CalculationType } from '@/stores/type/calculation.type'

const { api } = useApi()

type State = BaseStoreType<CalculationCPTType> & {
  currentStrippedItem: CalculationCPTType | null
}

const baseUrl = API.CALCULATION_CPT.BASE
const baseStore: BaseStoreType<CalculationCPTType> = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<CalculationCPTType> = baseStore.actions
export const useCalculationCPTStore = defineStore({
  id: 'calculationCPT',
  state: (): State => ({
    ...baseStore.state,
    currentStrippedItem: null,
    loading: false,
    filterFields: ['name']
  }),
  actions: {
    ...baseStoreActions,
    resetAll(): void {
      this.$reset()
    },
    removeItemById(id: number): void {
      const item = this.items.find((item) => item.id === id)
      if (item) {
        this.removeItem(item)
      }
    },
    async fetchOrGetCalculationCPT(calculationCPT: CalculationCPTType): Promise<void> {
      const fetchedCalculationCPT = this.items.find((calculationCPTItem) => {
        return calculationCPT.id === calculationCPTItem.id
      })
      if (!fetchedCalculationCPT) {
        const response = await this.fetchItem(calculationCPT.id)
        if (response) {
          this.addItems([response])
          this.setCurrentItem(response)
        }
      } else {
        this.setCurrentItem(fetchedCalculationCPT)
      }
    },
    setCurrentStrippedItem(item: CalculationCPTType): void {
      this.currentStrippedItem = item
    },
    removeItemsByCalculationId(calculationId: number): void {
      const removeItems = this.items.filter((item) => item.calculation === calculationId)
      const index = removeItems.findIndex((item) => item.id === this.currentItem?.value?.id)
      if (index !== -1) {
        this.setCurrentItem({})
        this.setCurrentStrippedItem(null)
      }
      removeItems.forEach((item) => {
        this.removeItem(item)
      })
    },
    toggleSelectedItem(id: number): void {
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)

      const calculationStore = useCalculationStore()
      const items = this.getStrippedItems()
      const item = items.find((item) => item.id === id)

      if (index !== -1) {
        this.selectedItems.splice(index, 1)
        calculationStore.removeSelectedItem(item?.calculation)
      } else {
        this.selectedItems.push(item)
        const selectedItemLength = this.getSelectedItemsByCalculationId(item?.calculation).length
        const itemLength = this.getItemsByCalculationId(item?.calculation).length
        if (selectedItemLength === itemLength) {
          calculationStore.addSelectedItem(item?.calculation)
        }
      }
    },
    selectAllItemsByCalculationId(id: number): void {
      const calculationStore = useCalculationStore()
      const calculation = calculationStore.getCalculationById(id)
      const selectedItems = calculation?.calculationcpt_set ?? []
      selectedItems.forEach((item) => {
        const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === item.id)
        if (index === -1) {
          this.selectedItems.push(item)
        }
      })
    },
    clearSelectedItemsByCalculationId(id: number): void {
      const calculationStore = useCalculationStore()
      const calculation = calculationStore.getCalculationById(id)
      const selectedItems = calculation?.calculationcpt_set ?? []
      selectedItems.forEach((item) => {
        const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === item.id)
        if (index !== -1) {
          this.selectedItems.splice(index, 1)
        }
      })
    },
    selectAllItems(): void {
      this.selectedItems = this.getStrippedItems()
    },
    clearSelectedItems(): void {
      this.selectedItems = []
    }
  },
  getters: {
    ...baseStore.getters,
    getStrippedItems: () => (): CalculationCPTType[] => {
      const calculationStore = useCalculationStore()
      const items = []
      calculationStore.items.forEach((calculation) => {
        items.push(...(calculation.calculationcpt_set ?? []))
      })
      return items
    },
    hasCalculationCPT:
      (state) =>
      (calculationId): boolean => {
        return state.items.some((item) => item.calculation === calculationId)
      },
    getSelectedItemsByCalculationId:
      (state) =>
      (id: number): CalculationType[] => {
        return state.selectedItems.filter((item: CalculationCPTType) => item.calculation === id)
      },
    getItemsByCalculationId:
      () =>
      (id: number): GiPointType[] => {
        const calculationStore = useCalculationStore()
        const calculation = calculationStore.getCalculationById(id)
        return calculation?.calculationcpt_set ?? []
      }
  }
})
