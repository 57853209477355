import { onMounted, onUnmounted, Ref } from 'vue'
import type { StoreGeneric } from 'pinia'

type Direction = 'left' | 'right'

interface ParamsObj {
  ref: Ref<HTMLElement | null>
  widthProperty: string
  store: StoreGeneric
  elementGetter?: (refElement: Ref<HTMLElement | null>) => HTMLElement
  direction?: Direction
  resizer: string[]
}

export const useResizeableElement = ({
  ref,
  store,
  widthProperty,
  elementGetter,
  direction,
  resizer
}: ParamsObj) => {
  let overBorderWidth = false
  let overBorderHeight = false
  let isWidthResizing = false
  let isHeightResizing = false
  const defaultBorderColor = 'rgba(0, 0, 0, 0.1)'
  const hoverBorderColor = 'rgba(0, 0, 0, 0.3)'

  const setEvents = (el: HTMLElement) => {
    if (!el) return
    const resizeWidth = resizeWidthHandler(el, widthProperty, direction)
    const resizeHeight = resizeHeightHandler(el, widthProperty)
    const mouseDown = mouseDownHandler(el, resizeHeight, resizeWidth)
    const mouseUp = mouseUpHandler(el, resizeHeight, resizeWidth)
    const mouseOver = mouseOverHandler(el)
    const mouseLeave = mouseLeaveHandler(el)

    el.addEventListener('mousemove', mouseOver, false)
    el.addEventListener('mouseleave', mouseLeave, false)
    el.addEventListener('mousedown', mouseDown, false)
    document.addEventListener('mouseup', mouseUp, false)
  }

  const removeEvents = (el: HTMLElement) => {
    if (!el) return
    const resizeWidth = resizeWidthHandler(el, widthProperty, direction)
    const resizeHeight = resizeHeightHandler(el, widthProperty)
    const mouseDown = mouseDownHandler(el, resizeHeight, resizeWidth)
    const mouseUp = mouseUpHandler(el, resizeHeight, resizeWidth)
    const mouseOver = mouseOverHandler(el)

    el.removeEventListener('mouseover', mouseOver, false)
    el.removeEventListener('mousedown', mouseDown, false)
    document.removeEventListener('mouseup', mouseUp, false)
  }

  const mouseDownHandler =
    (
      el: HTMLElement,
      resizeHeight: (e: MouseEvent) => void,
      resizeWidth: (e: MouseEvent) => void
    ) =>
    (e: MouseEvent) => {
      isOverBorderWidth(el, e)
      isOverBorderHeight(el, e)
      if (overBorderWidth) {
        isWidthResizing = true
        el.style.transition = 'initial'
        document.addEventListener('mousemove', resizeWidth, false)
      } else if (overBorderHeight) {
        isHeightResizing = true
        el.style.transition = 'initial'
        document.addEventListener('mousemove', resizeHeight, false)
      }
    }

  const mouseUpHandler =
    (
      el: HTMLElement,
      resizeHeight: (e: MouseEvent) => void,
      resizeWidth: (e: MouseEvent) => void
    ) =>
    () => {
      isWidthResizing = false
      isHeightResizing = false
      el.style.transition = ''
      document.body.style.cursor = 'default'
      document.removeEventListener('mousemove', resizeHeight, false)
      document.removeEventListener('mousemove', resizeWidth, false)
      setDefaultStyle(el)
    }

  const setDefaultStyle = (el: HTMLElement) => {
    el.style.borderBottomColor = defaultBorderColor
    el.style.borderRightColor = defaultBorderColor
    el.style.borderTopColor = defaultBorderColor
    el.style.borderLeftColor = defaultBorderColor
  }

  const resizeWidthHandler =
    (el: HTMLElement, property: string, direction: Direction = 'right') =>
    (e: MouseEvent) => {
      document.body.style.cursor = 'ew-resize'
      const calculatedWidth =
        direction === 'left'
          ? Math.abs(el.getBoundingClientRect().right - e.clientX)
          : Math.abs(e.clientX - el.getBoundingClientRect().left)
      if (
        calculatedWidth < store[property].minWidth ||
        calculatedWidth > store[property].maxWidth
      ) {
        return
      }
      store[property].width = calculatedWidth
    }

  const resizeHeightHandler = (el: HTMLElement, property: string) => (e: MouseEvent) => {
    document.body.style.cursor = 'ns-resize'
    if (overBorderHeight) {
      const calculatedHeight = Math.abs(e.clientY - el.getBoundingClientRect().top)
      store[property].height =
        calculatedHeight < store[property].minHeight ? store[property].minHeight : calculatedHeight

      if (calculatedHeight >= window.innerHeight - 200) {
        store[property].height = window.innerHeight - 200
      }
    }
  }

  const isOverBorderWidth = (el: HTMLElement, e: MouseEvent) => {
    const rect = el.getBoundingClientRect()
    const mouseX = e.clientX - rect.left
    const leftOver = resizer.includes('left') ? mouseX <= 5 : false
    const rightOver = resizer.includes('right')
      ? -8 < mouseX - rect.width && mouseX - rect.width < 5
      : false
    overBorderWidth = leftOver || rightOver
  }

  const isOverBorderHeight = (el: HTMLElement, e: MouseEvent) => {
    const rect = el.getBoundingClientRect()
    const mouseY = e.clientY - rect.top
    const topOver = resizer.includes('top') ? mouseY <= 5 : false
    const bottomOver = resizer.includes('bottom')
      ? -8 < mouseY - rect.height && mouseY - rect.height < 5
      : false
    overBorderHeight = topOver || bottomOver
  }

  const mouseLeaveHandler = (el: HTMLElement) => () => {
    if (isWidthResizing || isHeightResizing) {
      return
    }
    overBorderWidth = false
    document.body.style.cursor = ''
    setDefaultStyle(el)
  }

  const mouseOverHandler = (el: HTMLElement) => (e: MouseEvent) => {
    isOverBorderWidth(el, e)
    isOverBorderHeight(el, e)
    document.body.style.cursor = overBorderWidth ? 'ew-resize' : overBorderHeight ? 'ns-resize' : ''
    el.style.borderBottomColor = overBorderHeight ? hoverBorderColor : defaultBorderColor
    el.style.borderRightColor = overBorderWidth ? hoverBorderColor : defaultBorderColor
  }

  onMounted(() => {
    if (!ref.value) return
    if (typeof elementGetter == 'function') {
      ref.value = elementGetter(ref)
    }
    setEvents(ref.value)
  })

  onUnmounted(() => {
    if (!ref.value) return
    let refValue = ref.value
    if (typeof elementGetter == 'function') {
      refValue = elementGetter(ref)
    }
    removeEvents(refValue)
  })
}

// for refs that are vuetify components
export const vDrawerElementGetter = (refElement: Ref<HTMLElement | null>): HTMLElement => {
  if (refElement.value && '$el' in refElement.value) {
    const el = refElement.value.$el as any
    return el.nextSibling as HTMLElement
  }
  return refElement.value as HTMLElement
}
