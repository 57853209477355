<script lang="ts" setup>
import { computed, onMounted, provide, watch } from 'vue'
import type { PlotData } from 'plotly.js-basic-dist'

import ChartTab from '@/components/result-drawer/ChartTab.vue'
import CalculationForm from '@/components/calculation/CalculationForm.vue'
import ChartLegend from '@/components/result-drawer/ChartLegend.vue'

import { cptChartDataFromCalculationCPT } from '@/utils/calculation-cpt/chartDataCalculationCPT'
import { cptChartDataFromCPT } from '@/utils/cpt/chartDataCPT'

import type { chartComponentType } from '@/types/chartComponent.type'
import type { CalculationType } from '@/stores/type/calculation.type'
import type { ChoiceType } from '@/stores/type/store.type'
import { DEFAULT_INPUT_COLOR, METHOD_LINE_COLOR } from '@/utils/lineColor'
import type { LiquefactionMethodType } from '@/stores/type/calculationCPTDataPoint.type'
import type { chartInputInfoType, chartOutputInfoType } from '@/types/chartType'
import type { GiPointType } from '@/stores/type/giPoint.type'

const cptStore = useCPTStore()
const calculationCPTStore = useCalculationCPTStore()
const calculationStore = useCalculationStore()
const giPointStore = useGiPointStore()

const { currentItem: currentCPT } = storeToRefs(cptStore)
const { currentItem: currentCalculationCPT } = storeToRefs(calculationCPTStore)
const { options } = storeToRefs(calculationStore)

const tab = ref<'calculation' | 'interpretation' | 'liquefaction'>('calculation')

const currentCalculation = computed<CalculationType | null>(() => {
  return calculationStore.getItem(currentCalculationCPT.value?.calculation)
})

const liquefactionMethodChoices = computed<ChoiceType<LiquefactionMethodType>[]>(() => {
  return options.value?.actions['POST']['liquefaction_method'].child.choices
})

const showLiqMethodLegend = ref<LiquefactionMethodType[]>([])
provide('showLiqMethodLegend', showLiqMethodLegend)

type legendDataType = { display_name: string; color: string; value: LiquefactionMethodType }

const legendData = computed(() => {
  const legend: legendDataType[] = []
  const liquefaction_method = currentCalculation.value?.liquefaction_method
  liquefactionMethodChoices.value.forEach((item) => {
    const color = METHOD_LINE_COLOR[item.value]
    if (color && liquefaction_method?.includes(item.value)) {
      legend.push({ display_name: item.display_name, color, value: item.value })
    }
  })
  return legend
})

const chartInputInfo = ref<chartInputInfoType[]>([
  {
    field: 'cone_resistance',
    xAxisTitle: 'q<sub>c</sub> [MPa]',
    title: 'Cone Resistance',
    color: DEFAULT_INPUT_COLOR,
    mode: 'marker'
  },
  {
    field: 'friction_ratio',
    xAxisTitle: 'r<sub>f</sub> [%]',
    title: 'Friction Ratio',
    color: DEFAULT_INPUT_COLOR,
    mode: 'marker'
  },
  {
    field: 'groundwater_pressure',
    xAxisTitle: 'u<sub>2</sub> [kPa]',
    title: 'Water Pressure',
    color: DEFAULT_INPUT_COLOR,
    mode: 'marker'
  }
])

// 1st row: CSR, CRR, factor of safety, settlement
// 2nd row: excess pore pressure- seismic, qc reduction seismic,  excess pore pressure- post seismic, qc reduction post seismic
// 3rd row: LPI, LDI, LSN, PL (if applied)
const chartOutputInfo = ref<chartOutputInfoType[]>([
  { field: 'CSR', xAxisTitle: 'CSR', title: 'Cycle Stress Ratio', mode: 'marker' },
  { field: 'CRR', xAxisTitle: 'CRR', title: 'Cycle Resistance Ratio', mode: 'marker' },
  {
    field: 'factor_of_safety_against_liquefaction',
    xAxisTitle: 'FS [-]',
    title: 'Factor of Safety',
    mode: 'marker'
  },
  { field: 's', xAxisTitle: 'Settlement [cm]', title: 'Settlement', mode: 'line' },
  {
    field: 'qc_s',
    xAxisTitle: 'qc reduction seismic [MPa]',
    title: 'qc reduction seismic',
    mode: 'marker'
  },
  {
    field: 'qc_ps',
    xAxisTitle: 'qc reduction post seismic [MPa]',
    title: 'qc reduction post seismic',
    mode: 'marker'
  },
  {
    field: 'rud_s',
    xAxisTitle: 'r<sub>u;seis</sub> [-]',
    title: 'Excess Pore <br> Pressure Seismic',
    mode: 'marker'
  },
  {
    field: 'rud_ps',
    xAxisTitle: 'r<sub>u;post-seis</sub> [-]',
    title: 'Excess Pore <br> Pressure Post Seismic',
    mode: 'marker'
  },
  { field: 'LPI', xAxisTitle: 'LPI [-]', title: 'Liquefaction <br> Potential Index', mode: 'line' },
  { field: 'LDI', xAxisTitle: 'LDI [-]', title: 'Lateral <br> Displacement Index', mode: 'line' },
  { field: 'LSN', xAxisTitle: 'LSN [-]', title: 'Liquefaction <br> Severity Number', mode: 'line' },
  { field: 'PL', xAxisTitle: 'PL [%]', title: 'Probability <br> of liquefaction', mode: 'marker' }
])

//Interpretation results
const icRobertson2010 = computed<PlotData[]>(() => {
  return cptChartDataFromCalculationCPT(
    currentCalculationCPT.value,
    'Ic_Robertson2010',
    liquefactionMethodChoices.value,
    showLiqMethodLegend.value,
    'marker'
  )
})

const inputChart = computed<chartComponentType[]>(() => {
  const data = []
  const currentGIPoint: GiPointType = giPointStore.getGIPointByCPTId(currentCPT.value?.id)
  const zCoordinates = currentGIPoint?.geometry?.coordinates[2] ?? 0
  chartInputInfo.value.forEach((item) => {
    const chartData = cptChartDataFromCPT(
      currentCPT.value,
      item.field,
      zCoordinates,
      item.color,
      item.mode
    )
    if (chartData.length) {
      data.push({
        data: chartData,
        xAxisTitle: item.xAxisTitle,
        title: item.title
      })
    }
  })
  if (icRobertson2010.value.length) {
    data.push({
      data: icRobertson2010.value,
      xAxisTitle: 'lc [-]',
      title: 'SBTn Index'
    })
  }
  return data
})

//Liquefaction results
const outputChart = computed<chartComponentType[]>(() => {
  const data: chartComponentType[] = []
  chartOutputInfo.value.forEach((item) => {
    const chartData = cptChartDataFromCalculationCPT(
      currentCalculationCPT.value,
      item.field,
      liquefactionMethodChoices.value,
      showLiqMethodLegend.value,
      item.mode
    )
    if (chartData.length) {
      data.push({
        data: chartData,
        xAxisTitle: item.xAxisTitle,
        title: item.title
      })
    }
  })
  return data
})

watch(
  () => currentCalculation.value,
  () => {
    showLiqMethodLegend.value = currentCalculation.value?.liquefaction_method || []
  }
)

onMounted(() => {
  showLiqMethodLegend.value = currentCalculation.value?.liquefaction_method || []
})
</script>

<template>
  <div class="fill-height">
    <v-tabs v-model="tab" bg-color="transparent" color="primary" grow>
      <v-tab value="calculation">Calculation input</v-tab>
      <v-tab value="interpretation">Interpretation results</v-tab>
      <v-tab value="liquefaction">Liquefaction results</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-card-text class="pa-0 fill-height">
      <v-window class="fill-height" v-model="tab">
        <v-window-item style="height: 75vh" value="calculation">
          <v-list class="overflow-auto fill-height" v-if="currentCalculation">
            <CalculationForm
              :store="calculationStore"
              :item="currentCalculation"
              readonly
            ></CalculationForm>
          </v-list>
        </v-window-item>

        <v-window-item style="height: 75vh" value="interpretation">
          <ChartTab class="overflow-auto" :data="inputChart" />
        </v-window-item>
        <v-window-item style="height: 72vh" value="liquefaction">
          <ChartLegend :data="legendData" />
          <v-divider />
          <ChartTab class="overflow-auto" :data="outputChart" />
        </v-window-item>
      </v-window>
    </v-card-text>
  </div>
</template>
