import type { PlotData } from 'plotly.js-basic-dist'

export const createTrace = (
  name: string,
  xData: number[],
  yData: number[],
  mode: string = 'lines+text',
  type: string = 'scatter',
  color?: string = '#000000',
  hide?: boolean = false,
  showLegend?: boolean = true
): PlotData => ({
  x: xData,
  y: yData,
  mode,
  type,
  name,
  showlegend: showLegend,
  line: {
    color: hide ? 'transparent' : color
  },
  marker: {
    color: color,
    size: 2
  }
})
