<script setup lang="ts">
import type { CalculationParameterType } from '@/stores/type/calculationParameter.type'
import type { CalculationType } from '@/stores/type/calculation.type'

import GIDataCollectionIndex from '@/components/gi-drawer/GIDataCollectionIndex.vue'
import CalculationForm from '@/components/calculation/CalculationForm.vue'
import { TextField } from '@rhdhv/vue-component-library'
import * as process from 'node:process'

const calculationParameterStore = useCalculationParameterStore()
const store = useCalculationStore()
const calculationCPTStore = useCalculationCPTStore()
const giDataUploadCollectionStore = useGiDataUploadCollectionStore()
const giPointStore = useGiPointStore()

const { currentItem, writeLoading, createDialog } = storeToRefs(store)
const { fetchLoading: fetchCalculationCPTLoading } = storeToRefs(calculationCPTStore)
const { currentItem: currentCalculationParameters } = storeToRefs(calculationParameterStore)
const { selectedItems: selectedGIPoints } = storeToRefs(giPointStore)

const name = ref(null)
const calcForm = ref(null)

const loading = computed(() => fetchCalculationCPTLoading.value || writeLoading.value)

function mapToCurrentItem(): void {
  if (currentCalculationParameters.value) {
    const keys = Object.keys(
      currentCalculationParameters.value
    ) as (keyof CalculationParameterType)[]
    keys.forEach((key) => {
      const calculationParameters = currentCalculationParameters.value

      // Check if currentItem.value is not null and calculationParameters is truthy
      if (currentItem.value && calculationParameters && key !== 'id') {
        store.setParameter(currentItem.value, key, calculationParameters[key])
      }
    })
  }
}

async function createCalculationByMode(item: CalculationType) {
  const mode = process.env.NODE_ENV
  if (mode === 'development') {
    const response = await store.create(item)
    if (response && response.id) {
      await calculationCPTStore.fetchItemsAdd({ calculation: response.id })
    }
  } else {
    await store.createCalculation(item)
  }
}

async function create() {
  const validation = await calcForm.value?.validate()
  if (!validation.valid) {
    return
  }
  mapToCurrentItem()
  const item = currentItem.value as CalculationType
  item.gipoint_set = selectedGIPoints.value
  await createCalculationByMode(item)
  store.toggleCreateDialog(undefined, false)
  name.value = null
  giPointStore.clearSelectedItems()
  giDataUploadCollectionStore.clearSelectedItems()
}

function close() {
  store.toggleCreateDialog(undefined, false)
}
</script>

<template>
  <v-dialog :model-value="createDialog" fullscreen persistent>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Create calculation</v-toolbar-title>
      </v-toolbar>
      <v-row no-gutters>
        <v-col cols="6">
          <v-card-title> Calculation Parameters</v-card-title>
          <v-list class="overflow-x-auto list-container">
            <v-form @submit.prevent ref="calcForm">
              <v-list-item class="pt-0 pb-0 ma-0">
                <TextField
                  v-if="currentItem"
                  :field="store.fields['name']"
                  :modelValue="currentItem"
                ></TextField>
              </v-list-item>
              <CalculationForm
                v-if="currentCalculationParameters"
                :store="calculationParameterStore"
                :item="currentCalculationParameters"
              ></CalculationForm>
            </v-form>
          </v-list>
        </v-col>
        <v-divider vertical />
        <v-col cols="6">
          <v-card-title> Select Ground Investigation Points</v-card-title>
          <GIDataCollectionIndex
            class="overflow-y-auto list-container"
            show-tree-only
          ></GIDataCollectionIndex>
        </v-col>
      </v-row>
      <v-divider />
      <v-lazy>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="primary"
            variant="text"
            class="text-none"
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="loading"
            :disabled="!selectedGIPoints.length"
            color="primary"
            variant="text"
            class="text-none"
            type="submit"
            @click="create()"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-lazy>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.list-container {
  height: calc(100vh - 168px);
}
</style>
