<script lang="ts" setup>
import { computed, inject, type Ref } from 'vue'
import { debounce } from 'lodash'

import CalculationDeleteButton from '@/components/calculation-drawer/CalculationDeleteButton.vue'

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
    default: ''
  }
})

const calculationStore = useCalculationStore()
const calculationParameterStore = useCalculationParameterStore()
const authorizationStore = useAuthorizationStore()

const { selectedItems } = storeToRefs(calculationStore)
const { currentItem: currentCalculationParameters } = storeToRefs(calculationParameterStore)

const emit = defineEmits(['update:modelValue'])

const dialog = inject<Ref<boolean>>('calculationsViewShowDialog')

const debouncedSearchQuery = computed({
  get() {
    return props.modelValue
  },
  set: debounce((query) => {
    emit('update:modelValue', query ?? '')
  }, 300)
})

const canAddCalculation = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['add_calculation']
  })
})
</script>

<template>
  <div
    class="d-flex justify-center align-center ga-2 pt-2 pb-2 px-2 bg-white"
    style="top: 0; position: sticky; z-index: 1000"
  >
    <v-text-field
      v-model="debouncedSearchQuery"
      color="primary"
      append-inner-icon="mdi-magnify"
      density="compact"
      label="Search..."
      hide-details
      single-line
      clearable
    ></v-text-field>
    <v-tooltip text="Create calculation" v-if="canAddCalculation">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          @click="calculationStore.toggleCreateDialog()"
          :disabled="!currentCalculationParameters?.id"
          class="text-none"
          prepend-icon="mdi-plus"
          color="primary"
          variant="tonal"
        >
          Create
        </v-btn>
      </template>
    </v-tooltip>
    <v-btn
      color="primary"
      variant="tonal"
      prepend-icon="mdi-table"
      class="text-none"
      hide-details
      single-line
      :disabled="!selectedItems.length"
      @click="dialog = true"
      >Calculation log
    </v-btn>
    <CalculationDeleteButton />
  </div>
</template>
