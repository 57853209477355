import type { LiquefactionMethodType } from '@/stores/type/calculationCPTDataPoint.type'

// Input CPT line color
export const DEFAULT_INPUT_COLOR = '#044b97'

// Liquefaction method line color
export const NCEER_COLOR = '#d07128'
export const BOULANGER_IDRISS_DET_COLOR = '#0000da'
export const BOULANGER_IDRISS_PROB_COLOR = '#02c002'
export const MOSS_PROB_COLOR = '#ff57ff'

export const METHOD_LINE_COLOR: { [M in LiquefactionMethodType]: string } = {
  NCEER2001: NCEER_COLOR,
  BoulangerIdriss2014Deterministic: BOULANGER_IDRISS_DET_COLOR,
  BoulangerIdriss2014Probabilistic: BOULANGER_IDRISS_PROB_COLOR,
  MossEtAl2006Probabilistic: MOSS_PROB_COLOR
}
